import { useAuthContext } from '../../hooks/useAuthContext';
import { useDocument } from '../../hooks/useDocument';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Fragment } from 'react';
import { Popover, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import { useCollection } from '../../hooks/useCollection';

// components
import AvailabilityToggle from '../availabilitytoggle/AvailabilityToggle';
import MissedCallList from '../missedcalllist/MissedCallList';
import LoadingSpinner from '../loadingspinner/LoadingSpinner';

export default function Header({ handleReturnCall }) {
  const { user } = useAuthContext();
  const { document } = useDocument('users', user.uid);
  const incomingCalls = useSelector((state) => state.call.incomingCalls);
  const missedCalls = useSelector((state) => state.missedCall.missedCalls);
  const { documents: incomingCallDocs } = useCollection('incomingCalls');

  const today = new Date();
  today.setHours(0, 0, 0, 0); // Set to start of today

  // Filter incoming calls that the current agent accepted that day
  const callsAcceptedToday =
    incomingCallDocs && document
      ? incomingCallDocs.filter(
          (call) =>
            call.actionType === 'accepted' &&
            call.agent === document.displayName &&
            new Date(call.callEnded) >= today &&
            new Date(call.callEnded) < new Date(),
        )
      : [];

  // Filter missed calls that the current agent accepted that day
  // const callsMissedToday = incomingCallDocs
  // ? incomingCallDocs.filter(call =>
  //     call.actionType === 'missed' &&
  //     new Date(call.actionTimestamp) >= today &&
  //     new Date(call.actionTimestamp) < new Date()
  //   )
  // : []

  const stats = [
    { label: 'Beantwortete Anrufe', value: callsAcceptedToday.length },
    { label: 'Wartende Anrufe', value: incomingCalls.length },
    { label: 'Verpasste Anrufe', value: missedCalls.length },
  ];

    // Function to translate the roles to German
    const translateUserRole = (role) => {
      switch (role) {
        case 'admin':
          return 'Admin';
        case 'team leader':
          return 'Teamleiter';
        case 'customer manager':
          return 'Kundenmanagement';
        case 'agent':
          return 'Agent';
        default:
          return role;
      }
    };

  if (!user || !document) {
    return <LoadingSpinner />;
  }

  return (
    <div className="rounded-lg bg-white shadow">
      <h2 className="sr-only" id="profile-overview-title">
        Profilübersicht
      </h2>
      <div className="bg-white p-6">
        <div className="sm:flex sm:items-center sm:justify-between">
          <div className="sm:flex sm:space-x-5">
            <div className="relative inline-block flex-shrink-0">
              <img
                className="mx-auto h-20 w-20 rounded-full object-cover"
                src={document.photoURL}
                alt=""
              />
              <span
                className={`absolute right-0 top-0 block h-3.5 w-3.5 translate-x-[-5px] rounded-full ring-2 ring-white ${document?.available ? 'bg-green-400' : 'bg-gray-400'}`}
              />
            </div>
            <div className="mt-4 text-center sm:mt-0 sm:pt-1 sm:text-left">
              <p className="text-sm font-medium text-gray-600">
                Willkommen zurück,
              </p>
              <p className="text-xl font-bold text-gray-900 sm:text-2xl">
                {document?.fullName}
              </p>
              <p className="text-sm font-medium text-gray-600">
                {translateUserRole(document?.role)}
              </p>
              <AvailabilityToggle userDocument={document} />
            </div>
          </div>
          <div className="mt-5 flex justify-center sm:mt-0">
            <Link
              to={`/agents/${user.uid}`}
              className="flex items-center justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
            >
              Profil ansehen
            </Link>
          </div>
        </div>
      </div>
      <div className="grid grid-cols-1 divide-y divide-gray-200 border-t border-gray-200 bg-gray-50 sm:grid-cols-3 sm:divide-x sm:divide-y-0">
        {stats.map((stat) => (
          <div
            key={stat.label}
            className="px-6 py-5 text-center text-sm font-medium"
          >
            {stat.label === 'Verpasste Anrufe' ? (
              stat.value > 0 ? (
                // Popover with dropdown for missed calls

                <Popover className="relative">
                  <Popover.Button className="flex w-full justify-center">
                    <span
                      className={`${stat.value > 0 ? 'font-bold text-red-500' : 'text-gray-900'}`}
                    >
                      {stat.value}
                    </span>
                    <span className="ml-2 cursor-pointer text-gray-600">
                      {stat.label}
                    </span>
                    <ChevronDownIcon
                      className="ml-1 h-4 w-4 self-center"
                      aria-hidden="true"
                    />
                  </Popover.Button>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-200"
                    enterFrom="opacity-0 translate-y-1"
                    enterTo="opacity-100 translate-y-0"
                    leave="transition ease-in duration-150"
                    leaveFrom="opacity-100 translate-y-0"
                    leaveTo="opacity-0 translate-y-1"
                  >
                    <Popover.Panel className="absolute z-10 mt-2 w-full max-w-sm px-4 sm:px-0 lg:max-w-3xl">
                      <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
                        <div className="relative z-50 bg-white p-7">
                          <MissedCallList handleReturnCall={handleReturnCall} />
                        </div>
                      </div>
                    </Popover.Panel>
                  </Transition>
                </Popover>
              ) : (
                // Just the stat for missed calls without dropdown
                <>
                  <span
                    className={`${stat.value > 0 ? 'font-bold text-red-500' : 'text-gray-900'}`}
                  >
                    {stat.value}
                  </span>{' '}
                  <span className="text-gray-600">{stat.label}</span>
                </>
              )
            ) : (
              // For 'Calls answered' and 'Calls waiting'
              <>
                <span
                  className={`${stat.label === 'Beantwortete Anrufe' && stat.value > 0 ? 'font-bold text-green-500' : stat.value > 0 ? 'font-bold text-red-500' : 'text-gray-900'}`}
                >
                  {stat.value}
                </span>{' '}
                <span className="text-gray-600">{stat.label}</span>
              </>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}

// {stats.map((stat) => (
//   <div key={stat.label} className="px-6 py-5 text-center text-sm font-medium">
//     <span className="text-gray-900">{stat.value}</span> <span className="text-gray-600">{stat.label}</span>
//   </div>
// ))}
