import { useEffect, useRef } from 'react'
import { Player } from '@lordicon/react'

// styles & icons
import { XCircleIcon } from '@heroicons/react/24/outline'

// import the phone json from assets
const ICON = require('../../assets/ringing_phone_solid.json');

export default function CallPending({ handleCancelCall, room }) {
  const playerRef = useRef(null);

  useEffect(() => {
      playerRef.current?.playFromBeginning();
  }, [playerRef])


  return (
    <div className="fixed inset-0 bg-black flex flex-col items-center justify-center">
    {room && (
      <div className="absolute top-2.5 right-2.5">
        <XCircleIcon
        className="w-10 h-10 text-white cursor-pointer"
        onClick={handleCancelCall}
        aria-label="cancel"
        />
        <p className="text-white text-center mt-1">Cancel</p>
      </div>
    )}
    <Player
          ref={playerRef}
          size={96}
          icon={ ICON }
          colorize='#FFFFFF'
          onComplete={() => playerRef.current?.playFromBeginning()}
      />
      <p className="text-white text-center mt-6 text-2xl">Calling...</p>
    </div>
  )
}
