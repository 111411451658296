import { Link } from 'react-router-dom'

export default function ClientList({ clients }) {
  return (
    <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 mt-10">
      {clients.length === 0 && <p>Noch keine Kunden!</p>}
    {clients.map((client) => (
      <div
        key={client.id}
        className="relative flex items-center space-x-3 rounded-lg border border-gray-300 bg-white px-6 py-5 shadow-sm focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 hover:border-gray-400"
      >
        <div className="flex-shrink-0">
          <img className="h-10 w-10 rounded-full object-contain" src={client.logoUrl} alt="" />
        </div>
        <div className="min-w-0 flex-1">
          <Link to={`/clients/${client.id}`}
          className="focus:outline-none"
          >
            <span className="absolute inset-0" aria-hidden="true" />
            <p className="text-sm font-medium text-gray-900">{client.name}</p>
            <p className="truncate text-sm text-gray-500">Standorte: {client.locations.length}</p>
          </Link>
        </div>
      </div>
    ))}
  </div>
  )
}
