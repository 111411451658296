import { useState, useEffect, useRef } from 'react';
import { useAuthContext } from '../hooks/useAuthContext';
import { useNavigate } from 'react-router-dom';

// firebase imports
import { auth } from '../firebase/config';
import { signInWithEmailAndPassword } from 'firebase/auth';

export const useLogin = () => {
  // const [isCancelled, setIsCancelled] = useState(false);
  const [error, setError] = useState(null);
  const [isPending, setIsPending] = useState(false);
  const { dispatch: authDispatch } = useAuthContext();
  const isMounted = useRef(true); // useRef to track mounted state instead of the isCancelled variable
  const navigate = useNavigate();


  const login = async (email, password) => {
    setError(null);
    setIsPending(true);

    try {
      // log user in
      const res = await signInWithEmailAndPassword(auth, email, password);

      if (res.user) {
        const idTokenResult = await res.user.getIdTokenResult();
        const role = idTokenResult.claims.role;

        // Dispatch the login action with user and role
        authDispatch({ type: 'LOGIN', payload: { user: res.user, role } });

        if (isMounted.current) {
          setIsPending(false);
          setError(null);
          navigate('/'); // Navigate to home on successful login

        }
      }
    } catch (err) {
      console.error('Error logging in', err.message);
      if (isMounted.current) {
        if (err.message === "Firebase: Error (auth/invalid-credential).") {
          setError('Fehler: Ungültige Anmeldedaten.')
        } else {
          setError('Fehler: Ein Fehler ist aufgetreten.')
        }
        setIsPending(false);
      }
    }
  };

  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false; // set to false when the component unmounts
    };
  }, []);



  return { login, isPending, error };
};
