import { useTwilioTracks } from '../../hooks/useTwilioTracks'
import { useState, useRef, useEffect } from 'react'
import { Player } from '@lordicon/react'

// components
import VideoButtons from './VideoButtons'

// icons
const ICON = require('../../assets/avatar_icon.json')

export default function VideoCall({
  remoteVideoRef,
  localVideoRef,
  room,
  callType,
  setShowVideo,
  isAgent,
  isAgentCameraOn,
  isAgentMicOn
  }) {
  const { toggleCamera, toggleMicrophone, reattachRemoteVideoTrack } = useTwilioTracks(room, remoteVideoRef, localVideoRef)
  const [isCameraOn, setIsCameraOn] = useState(true)
  const [isMicOn, setIsMicOn] = useState(true)
  const playerRef = useRef(null)

  useEffect(() => {
    playerRef.current?.playFromBeginning();
  }, [])

  useEffect(() => {
    if (!isAgent && isAgentCameraOn) {
      // Call the function to reattach the remote video track
      reattachRemoteVideoTrack()
    }
  }, [isAgent, isAgentCameraOn, reattachRemoteVideoTrack])

  const handleToggleCamera = () => {
    toggleCamera()
    setIsCameraOn(prev => !prev)
  }

  const handleToggleMicrophone = () => {
    toggleMicrophone()
    setIsMicOn(prev => !prev)
  }

  return (
    <div className="fixed inset-0 bg-black flex items-center justify-center z-50">
      {isAgent || isAgentCameraOn ? (

        <div className="mx-auto px-4 sm:px-6 lg:px-8" style={{ width: '1000px', height: '700px' }} ref={remoteVideoRef} />

      ) : (

        <Player
        ref={playerRef}
        size={180}
        icon={ ICON }
        colorize='#FFFFFF'
        />

      )}
      {/* <div className="mx-auto px-4 sm:px-6 lg:px-8" style={{ width: '1000px', height: '700px' }} ref={remoteVideoRef} /> */}

      <div className="absolute bottom-4 right-4 w-40 lg:w-60 hidden sm:block" ref={localVideoRef} />
      <VideoButtons
        room={room}
        setShowVideo={setShowVideo}
        callType={callType}
        isAgent={isAgent}
        handleToggleCamera={handleToggleCamera}
        handleToggleMicrophone={handleToggleMicrophone}
        isCameraOn={isCameraOn}
        isMicOn={isMicOn}
        isAgentMicOn={isAgentMicOn}
      />
    </div>
  )
}
