import { useAuthContext } from '../../hooks/useAuthContext'
import { useFirestore } from '../../hooks/useFirestore'
import { useState, useEffect, useRef } from 'react'
import { useDocument } from '../../hooks/useDocument'
import { useDispatch, useSelector } from 'react-redux'
import { cancelCall } from '../../features/calls/callSlice'
import { useTwilioVideo } from '../../hooks/useTwilioVideo'
import { useTwilioTracks } from '../../hooks/useTwilioTracks'
import { doc, setDoc, getDoc } from 'firebase/firestore'
import { db } from '../../firebase/config'
import { useSocketContext } from '../../hooks/useSocketContext'

// components
import VideoCall from '../../components/videocall/VideoCall'
import NotificationDialog from '../../components/notificationdialog/NotificationDialog'
import CallList from '../../components/calllist/CallList'
// import UserList from '../../components/userlist/UserList'
import Header from '../../components/dashboardheader/Header'
import LoadingSpinner from '../../components/loadingspinner/LoadingSpinner'

export default function Dashboard() {
  const { user } = useAuthContext()
  const { updateDocument: updateIncomingCallDocument } = useFirestore('incomingCalls')
  const { document: userDocument } = useDocument('users', user.uid)
  const [openNotificationDialog, setOpenNotificationDialog] = useState(false)
  const localVideoRef = useRef()
  const remoteVideoRef = useRef()
  const [roomError, setRoomError] = useState(null)
  const [showVideo, setShowVideo] = useState(false)
  const [openEndCallDialog, setOpenEndCallDialog] = useState(false)
  const { joinRoom, room, setError } = useTwilioVideo()
  const {
    detachLocalTracks,
    attachSubscribedTracks,
    disconnectRoom,
    attachLocalVideoTrack,
    attachLocalAudioTrack
   } = useTwilioTracks(room, remoteVideoRef, localVideoRef)
  const [callType, setCallType] = useState(null)
  const incomingCalls = useSelector((state) => state.call.incomingCalls);
  const dispatch = useDispatch()
  const socket = useSocketContext()

  useEffect(() => {

    if (room) {

      if (callType === 'incoming') {
        attachLocalVideoTrack()
        attachLocalAudioTrack()
      // Subscribe to remote tracks (participant already in room)
      room.participants.forEach((participant) => {
        participant.on('trackSubscribed', (track) => {
          attachSubscribedTracks(track)
        })
      })
      }

      if (callType === 'outgoing') {
        attachLocalVideoTrack()
        attachLocalAudioTrack()
      // Listen for remote participant to join the room, then subscribe to remote tracks
      room.on('participantConnected', participant => {
        participant.on('trackSubscribed', track => {
          attachSubscribedTracks(track)
        })
      })
      }

      // Detach the local media elements (takes place when either agent or user disconnects call)
      room.on('disconnected', room => {
        detachLocalTracks()
      })

      // Disconnect the room if the agent ends the call
      room.on('participantDisconnected', participant => {
        disconnectRoom()
        setShowVideo(false)
        setOpenEndCallDialog(true)
      })
    }

  }, [room, attachLocalAudioTrack, attachLocalVideoTrack, attachSubscribedTracks, detachLocalTracks, disconnectRoom, callType])

  const handleAcceptCall = async (roomData) => {

    setCallType('incoming')

    try {
      const newRoom = await joinRoom(user.uid, roomData.roomName);
      setShowVideo(true);

      const updates = {
        actionType: 'accepted',
        actionTimestamp: new Date().toISOString(),
        agent: userDocument.displayName,
      };

      await updateIncomingCallDocument(newRoom.sid, updates);
      if (socket) {
        socket.emit('call-accepted', newRoom);
      }
    } catch (error) {
      console.error('Error during call:', error);
      // TODO: check error and translate to german
      setRoomError(error.message);
      setOpenNotificationDialog(true);
    }
  };

  const handleCloseNotificationDialog = () => {
    setOpenNotificationDialog(false)
    setRoomError(null)
  }

  const handleCloseEndCallDialog = () => {
    setOpenEndCallDialog(false)
  }

  const handleDeclineCall = async (call) => {
    // remove call notification for all agents
    dispatch(cancelCall(call))

    // update incoming call db doc
    const updates = {
      actionType: 'declined',
      actionTimestamp: new Date().toISOString(),
      agent: userDocument.displayName,
      completed: true
    }

    await updateIncomingCallDocument(call.roomSid, updates)

    // emit declined event so we can listen and disconnect the room for the user
    if (socket) {
      socket.emit('call-declined', call.locationId, call)
    }
  };

  // function to save outgoing calls to the db:
  const saveOutgoingCall = async (room) => {
    const outgoingCall = {
      roomName: room.name,
      // TODO: consider using server timestamp!
      callStarted: new Date().toISOString(),
      agent: userDocument.displayName,
      completed: false
    }

    const outgoingCallDocRef = doc(db, 'outgoingCalls', room.sid)

    try {
      await setDoc(outgoingCallDocRef, outgoingCall); // Set the document at the specified reference
      return outgoingCallDocRef; // Return the document reference
    } catch (e) {
      console.error('Error adding document: ', e);
    }
  }

  // here the call we pass is the incoming call db doc with the id of the room sid of the missed call
  // dispatch action to remove from the redux array in this function
  const handleReturnCall = async (call) => {
    try {
      setCallType('outgoing');
      const roomName = call.roomName;
      const newRoom = await joinRoom(user.uid, roomName);

      // IF WE REMOVE THIS DISPATCH, MISSED CALLS ARE NOT REMOVED FOR ANYONE.
      // dispatch(removeMissedCall(call))
      setShowVideo(true);

      // Save outgoingCall record to the db and get ref
      const outgoingCallRef = await saveOutgoingCall(newRoom)

      // Fetch the newly created record from the db
      const outgoingCallDoc = await getDoc(outgoingCallRef)

      // Check path to the db record exists, then emit socket event with relevant data
      if (outgoingCallDoc.exists()) {
        const outgoingCallData = outgoingCallDoc.data()
        const documentId = outgoingCallDoc.id
        if (socket) {
          const outgoingRoom = {
            roomName: outgoingCallData.roomName,
            roomSid: documentId,
            agent: outgoingCallData.agent,
            timestamp: new Date().toISOString()
          }
          socket.emit('outgoing-call', call.locationId, outgoingRoom)
          // IMPORTANT! We need to pass the 'call' object below as that has the same roomSid as the
          // missed call in the missedCalls array in the redux store. If we pass outgoingRoom it has
          // a new roomSid and the logic to remove it from the array won't work
          socket.emit('remove-missedcall', call)
        }
      }

    } catch (error) {
      console.error('Error during call:', error);
      setError(error); // Set the error using setError from useTwilioVideo hook
      setShowVideo(false);
      setOpenNotificationDialog(true);
    }
  };

  // Check if user or document is null before accessing properties
  if (!user || !document) {
    return <LoadingSpinner />;
  }


  return (
     <>
     {showVideo ? (
       <VideoCall
        localVideoRef={localVideoRef}
        remoteVideoRef={remoteVideoRef}
        room={room}
        callType={callType}
        showVideo={showVideo}
        setShowVideo={setShowVideo}
        isAgent={true}
        />
      ) : (
      <>
        {/* <UserList /> */}
        <Header
        handleReturnCall={handleReturnCall}
        />
        <div>
          {incomingCalls && userDocument?.available && (
            <CallList
            incomingCalls={incomingCalls}
            handleAcceptCall={handleAcceptCall}
            handleDeclineCall={handleDeclineCall}
            />
          )}
        </div>
        {openNotificationDialog && (
          <NotificationDialog
          open={openNotificationDialog}
          title='Anruf von einem anderen Agenten angenommen'
          text={roomError}
          close={handleCloseNotificationDialog}
          />
        )}
        {openEndCallDialog && (
          <NotificationDialog
          open={openEndCallDialog}
          title='Anruf beendet'
          text='Kunde hat den Anruf beendet'
          close={handleCloseEndCallDialog}
           />
        )}
       </>
      )}
     </>
    )}
