import { useCollection } from '../../hooks/useCollection';
import { useState } from 'react';

// styles & components
import Pagination from '../../components/pagination/Pagination';
import DateModal from '../../components/datemodal/DateModal';

// icons
import {
  PhoneArrowDownLeftIcon,
  PhoneArrowUpRightIcon,
  PhoneXMarkIcon,
} from '@heroicons/react/24/outline';

export default function CallHistory() {
  const {
    documents: incomingCalls,
    error: incomingError,
    isPending: isPendingIncoming,
  } = useCollection('incomingCalls');
  const {
    documents: outgoingCalls,
    error: outgoingError,
    isPending: isPendingOutgoing,
  } = useCollection('outgoingCalls');
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(25);
  const [openDateModal, setOpenDateModal] = useState(false);

  // const itemsPerPage = 10

  const handleCloseDateModal = () => {
    setOpenDateModal(false);
  };

  // Create and set an isOutgoing property for calls, combine
  // incoming and outgoing as 'allCalls', and sort the calls with most recent first
  const allCalls = [
    ...(incomingCalls?.map((call) => ({ ...call, isOutgoing: false })) || []),
    ...(outgoingCalls?.map((call) => ({ ...call, isOutgoing: true })) || []),
  ]
    .filter((call) => call.completed)
    .sort((a, b) => new Date(b.callStarted) - new Date(a.callStarted));

  // Divide the calls into pages according to items per page no. set above
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = allCalls.slice(indexOfFirstItem, indexOfLastItem);

  // Function to handle changing the pages
  const onPageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  // Function to format the date
  const formatDate = (isoString) => {
    const date = new Date(isoString);
    return date.toLocaleDateString(); // Formats the date in a user-friendly way
  };

  // Function to format the time
  const formatTime = (seconds) => {
    // If seconds are null or not a number, return 'N/A'
    if (seconds === null || isNaN(seconds)) return 'n.z.';

    // Calculate minutes and remaining seconds
    const mins = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;

    // Format the time string
    if (mins === 0) {
      return `${remainingSeconds} secs`;
    }
    return `${mins} min ${remainingSeconds < 10 ? '0' : ''}${remainingSeconds} secs`;
  };

  // Function to calculate time difference (for wait time, duration)
  const calculateTimeDifferenceInSeconds = (startTime, endTime) => {
    // Ensure both times are valid
    if (!startTime || !endTime) return null;

    // Calculate the difference in seconds
    const start = new Date(startTime);
    const end = new Date(endTime);
    return Math.round((end - start) / 1000); // Convert to seconds and round
  };

  // Function to convert data to CSV
  const convertToCSV = (data) => {
    let csvContent = 'data:text/csv;charset=utf-8,';

    // Headers
    csvContent +=
      'Type,Date,Location,Room SID,Agent,Outcome,Wait Time,Call Duration\n';

    // Rows
    data.forEach((doc) => {
      const callType = doc.isOutgoing ? 'Outgoing' : 'Incoming';
      let outcome = 'n.z.';
      let waitTimeFormatted = 'n.z.';

      // Calculate wait time and outcome for incoming calls
      if (!doc.isOutgoing && doc.actionType) {
        outcome = doc.actionType;
        if (doc.actionType === 'accepted' && doc.actionTimestamp) {
          const waitTimeInSeconds = calculateTimeDifferenceInSeconds(
            doc.callStarted,
            doc.actionTimestamp,
          );
          waitTimeFormatted = formatTime(waitTimeInSeconds);
        }
      }

      // Calculate call duration
      const durationInSeconds = doc.callEnded
        ? calculateTimeDifferenceInSeconds(doc.callStarted, doc.callEnded)
        : null;
      const durationFormatted =
        durationInSeconds !== null ? formatTime(durationInSeconds) : 'n.z.';

      let row = `${callType},${formatDate(doc.callStarted)},${doc.roomName},${doc.id},${doc.agent || 'n.z.'},${outcome},${waitTimeFormatted},${durationFormatted}\n`;
      csvContent += row;
    });

    return csvContent;
  };

  // Function to export to CSV
  const downloadCSV = (data) => {
    const csvContent = convertToCSV(data);
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement('a');
    link.setAttribute('href', encodedUri);
    link.setAttribute('download', 'call_history.csv');
    document.body.appendChild(link); // Required for FF
    link.click();
    document.body.removeChild(link);
  };

  // Function to handle the date range
  const handleDateRangeSubmit = ({ startDate, endDate }) => {
    // Create start and end Date objects at the beginning and end of the respective days
    const start = new Date(startDate);
    start.setUTCHours(0, 0, 0, 0); // Set time to the start of the day in UTC
    const end = new Date(endDate);
    end.setUTCHours(23, 59, 59, 999); // Set time to the end of the day in UTC

    // Filter calls between the start and end dates (inclusive)
    const filteredCalls = allCalls.filter((call) => {
      const callDate = new Date(call.callStarted);
      return callDate >= start && callDate <= end;
    });

    // Download CSV with filtered calls
    downloadCSV(filteredCalls);
  };

  // Function to translate the incomingCall action types into German for display in the table
  const translateActionType = (actionType) => {
    const translations = {
      accepted: 'angenommen',
      missed: 'verpasst',
      cancelled: 'abgebrochen',
      declined: 'abgelehnt',
    };

    return translations[actionType] || 'n.z.'; // Default to 'n.z.' if no translation is found
  };

  // Function to display the call details in the table
  const renderCallDetails = (doc) => {
    // Determine if the call is incoming or outgoing
    const isOutgoing = outgoingCalls?.some(
      (outgoing) => outgoing.id === doc.id,
    );

    // Determine if call is missed
    const isMissed = doc.actionType === 'missed';

    let icon;

    if (isMissed) {
      icon = <PhoneXMarkIcon className="h-5 w-5 text-red-500" />;
    } else if (isOutgoing) {
      icon = <PhoneArrowUpRightIcon className="h-5 w-5 text-green-500" />;
    } else {
      icon = <PhoneArrowDownLeftIcon className="h-5 w-5 text-blue-500" />;
    }

    // Define a variable for the text color class
    const textColorClass = isMissed ? 'text-red-500' : 'text-gray-500';

    let waitTimeFormatted = 'n.z.';
    let durationFormatted = 'n.z.';

    // Calculate durationInSeconds outside of conditions since it's used for both incoming and outgoing
    const durationInSeconds = calculateTimeDifferenceInSeconds(
      doc.callStarted,
      doc.callEnded,
    );
    durationFormatted = formatTime(durationInSeconds);

    // Handle wait time for incoming calls
    if (!isOutgoing && doc.actionType === 'accepted' && doc.actionTimestamp) {
      const waitTimeInSeconds = calculateTimeDifferenceInSeconds(
        doc.callStarted,
        doc.actionTimestamp,
      );
      waitTimeFormatted = formatTime(waitTimeInSeconds);
    }

    return (
      <tr key={doc.id}>
        <td
          data-label="Type"
          className={`whitespace-nowrap py-2 pl-4 pr-3 text-sm ${textColorClass} sm:pl-0`}
        >
          {icon}
        </td>
        <td
          data-label="Date"
          className={`whitespace-nowrap px-2 py-2 text-sm font-medium ${textColorClass}`}
        >
          {formatDate(doc.callStarted)}
        </td>
        <td
          data-label="Location"
          className={`whitespace-nowrap px-2 py-2 text-sm ${textColorClass}`}
        >
          {doc.roomName}
        </td>
        <td
          data-label="RoomSid"
          className={`whitespace-nowrap px-2 py-2 text-sm ${textColorClass}`}
        >
          {doc.id}
        </td>
        <td
          data-label="Agent"
          className={`whitespace-nowrap px-2 py-2 text-sm ${textColorClass}`}
        >
          {doc.agent || 'n.z.'}
        </td>
        <td
          data-label="Outcome"
          className={`whitespace-nowrap px-2 py-2 text-sm ${textColorClass}`}
        >
          {isOutgoing ? 'n.z.' : translateActionType(doc.actionType)}
        </td>
        <td
          data-label="WaitTime"
          className={`whitespace-nowrap px-2 py-2 text-sm ${textColorClass}`}
        >
          {isOutgoing ? 'n.z.' : waitTimeFormatted}
        </td>
        <td
          data-label="CallDuration"
          className={`whitespace-nowrap px-2 py-2 text-sm ${textColorClass}`}
        >
          {durationFormatted}
        </td>
      </tr>
    );
  };

  return (
    <div className="w-full px-4 sm:px-6 lg:px-8">
      {openDateModal && (
        <DateModal
          open={openDateModal}
          close={handleCloseDateModal}
          onSubmit={handleDateRangeSubmit}
        />
      )}
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-base font-semibold leading-6 text-gray-900">
            Anrufverlauf
          </h1>
          <p className="mt-2 text-sm text-gray-700">
            Alle eingehenden und ausgehenden Anrufe.
          </p>
        </div>
        <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
          <button
            type="button"
            className="block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            // pass in currentItems to export just the current page, or allCalls to export all data
            // onClick={() => downloadCSV(currentItems)}
            onClick={() => setOpenDateModal(true)}
          >
            Als CSV exportieren
          </button>
        </div>
      </div>

      <div className="mt-8 flow-root">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            {(isPendingIncoming || isPendingOutgoing) && (
              <div>Anrufverlauf wird geladen...</div>
            )}
            {(incomingError || outgoingError) && (
              <div className="error">{incomingError || outgoingError}</div>
            )}
            <table className="min-w-full divide-y divide-gray-300">
              <thead>
                <tr>
                  <th
                    scope="col"
                    className="whitespace-nowrap py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
                  >
                    Typ
                  </th>
                  <th
                    scope="col"
                    className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    Datum
                  </th>
                  <th
                    scope="col"
                    className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    Standort
                  </th>
                  <th
                    scope="col"
                    className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    Room Sid
                  </th>
                  <th
                    scope="col"
                    className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    Agent
                  </th>
                  <th
                    scope="col"
                    className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    Ergebnis
                  </th>
                  <th
                    scope="col"
                    className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    Wartezeit
                  </th>
                  <th
                    scope="col"
                    className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    Gesprächsdauer
                  </th>
                </tr>
              </thead>

              <tbody className="divide-y divide-gray-200 bg-white">
                {currentItems.map(renderCallDetails)}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <Pagination
        currentPage={currentPage}
        totalItems={allCalls.length}
        itemsPerPage={itemsPerPage}
        setItemsPerPage={setItemsPerPage}
        onPageChange={onPageChange}
        indexOfFirstItem={indexOfFirstItem}
        indexOfLastItem={indexOfLastItem}
      />
    </div>
  );
}
