import CallListItem from './CallListItem'

export default function CallList({ incomingCalls, handleAcceptCall, handleDeclineCall }) {

  return (
<>
<div>
<ul>
  {incomingCalls.map((call) => (
    <CallListItem
      key={call.roomSid}
      call={call}
      incomingCalls={incomingCalls}
      handleAcceptCall={handleAcceptCall}
      handleDeclineCall={handleDeclineCall}
    />
  ))}
</ul>
</div>
  </>
  )
}
