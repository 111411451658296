import { Link } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { useDocument } from '../../hooks/useDocument';
import { useCollection } from '../../hooks/useCollection';
import { useFirestore } from '../../hooks/useFirestore';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { useAuthContext } from '../../hooks/useAuthContext';
import { Alert } from '@mui/material';

// styles, images & components
import LocationList from '../../components/locationlist/LocationList';
import PageHeader from '../../components/pageheader/PageHeader';
import DeleteAlert from '../../components/deletealert/DeleteAlert';

export default function Client() {
  const { id } = useParams();
  const { document: clientDocument, error: clientError } = useDocument(
    'clients',
    id,
  );
  const locationQuery = ['clientId', '==', id];
  const { documents: locationsDocuments, error: locationsError } =
    useCollection('locations', locationQuery);
  const { deleteDocument, response } = useFirestore('clients');
  const navigate = useNavigate();
  const [openDeleteClientAlert, setOpenDeleteClientAlert] = useState(false);
  const { role } = useAuthContext();
  const [error, setError] = useState(null);
  const [isDeleting, setIsDeleting] = useState(false);

  if (clientError && !isDeleting) {
    return (
      <Alert severity="error">
        Fehler: Daten konnten nicht geladen werden.
      </Alert>
    );
  }
  if (!clientDocument) {
    return <div className="loading">Kunde wird geladen...</div>;
  }

  // sort locations so that newest created appear top
  const sortedLocations = locationsDocuments
    ? locationsDocuments.sort((a, b) => b.createdAt - a.createdAt)
    : [];

  const handleDelete = async () => {
    setError(null);
    setIsDeleting(true)
    await deleteDocument(clientDocument.id);
    if (response.error || response.status !== 200) {
      setError('Fehler: Kunde konnte nicht gelöscht werden.');
      setIsDeleting(false)
    }
    navigate('/clients', { state: { deletionSuccess: true } });
    setOpenDeleteClientAlert(false);
  };

  return (
    <div>
      <PageHeader
        title={clientDocument.name}
        href={`/clients/${clientDocument.id}/newlocation`}
        addButtonText="Standort"
        image={clientDocument.logoUrl}
        role={role}
      >
        <button
          type="button"
          className="inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
          onClick={() => setOpenDeleteClientAlert(true)}
        >
          Löschen
        </button>
        <Link
          to={`/clients/${clientDocument.id}/edit`}
          className="ml-1 inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
        >
          Bearbeiten
        </Link>
      </PageHeader>
      {locationsError && (
        <Alert severity="error" className="mt-8">
          Fehler: Daten konnten nicht geladen werden.
        </Alert>
      )}
      {error && (
        <Alert severity="error" className="mt-8">
          {error}
        </Alert>
      )}
      {openDeleteClientAlert && (
        <DeleteAlert
          open={openDeleteClientAlert}
          close={() => setOpenDeleteClientAlert(false)}
          handleDelete={handleDelete}
          title={`${clientDocument.name} löschen`}
          text={`Bist du sicher, dass du ${clientDocument.name} dauerhaft löschen möchtest?`}
        ></DeleteAlert>
      )}
      {locationsDocuments && (
        <LocationList
          locations={sortedLocations}
          clientId={id}
          clientDocument={clientDocument}
          role={role}
        />
      )}
    </div>
  );
}
