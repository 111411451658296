import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  incomingCalls: []
}

const callSlice = createSlice({
  name: 'call',
  initialState,
  reducers: {
    initiateCall(state, action) {
      // Add the initiated call to incomingCalls array
      state.incomingCalls = [...state.incomingCalls, action.payload]
    },
    acceptCall(state, action) {
      // Remove the accepted call from incomingCalls array
      state.incomingCalls = state.incomingCalls.filter(call => call.roomSid !== action.payload.roomSid)
    },
    cancelCall(state, action) {
      // Remove the cancelled call from incomingCalls array
      state.incomingCalls = state.incomingCalls.filter(call => call.roomSid !== action.payload.roomSid)
    }
  }
})

export const { initiateCall, acceptCall, cancelCall } = callSlice.actions

export default callSlice.reducer
