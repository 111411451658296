import { Link, useNavigate } from 'react-router-dom';
import { useState } from 'react';
import axios from 'axios';
import { getAuth, sendSignInLinkToEmail } from 'firebase/auth';
import { useAuthContext } from '../../hooks/useAuthContext';
import { Alert } from '@mui/material';

// Images
import defaultThumbnail from '../../assets/default_thumbnail.png';

export default function CreateAgent() {
  const [fullName, setFullName] = useState('');
  const [displayName, setDisplayName] = useState('');
  const [email, setEmail] = useState('');
  const [newUserRole, setNewUserRole] = useState('agent');
  const [isPending, setIsPending] = useState(false);
  const auth = getAuth();
  const navigate = useNavigate();
  const { role } = useAuthContext();
  const [error, setError] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsPending(true);
    setError(null)

    const userData = {
      fullName,
      displayName,
      email,
      defaultThumbnail,
      role: newUserRole,
    };

    const token = await auth.currentUser.getIdToken();

    try {
      // CHANGE URL DEVELOPMENT VS PRODUCTION
      await axios.post('https://api.oracom.de/create-user', userData, {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: 'application/json',
        },
      });

      // Define actionCodeSettings
      const actionCodeSettings = {
        url: 'https://digital.oracom.de/finish-signup', // The URL to redirect to after email verification
        handleCodeInApp: true,
      };

      // Send the sign-in link to the user's email
      await sendSignInLinkToEmail(auth, email, actionCodeSettings);

      navigate('/agents');
      setIsPending(false);
    } catch (error) {
      console.error(
        'Error creating user:',
        error.response ? error.response.data : error.message,
      );
      setIsPending(false);

      // Check if error.response exists and contains a specific error message
      if (
        error.response &&
        error.response.data.error ===
          'The email address is improperly formatted.'
      ) {
        setError('Fehler: Die E-Mail-Adresse ist falsch formatiert.');
      } else {
        // Use a generic error message
        setError('Fehler: Ein Fehler ist aufgetreten.');
      }
    }
  };

  // Function to capitalize words
  function capitalizeWords(str) {
    if (!str) return '';

    return str
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  }

  return (
    <>
      <div className="space-y-10 divide-y divide-gray-900/10">
        <div className="grid grid-cols-1 gap-x-8 gap-y-8 md:grid-cols-3">
          <div className="px-4 sm:px-0">
            <h2 className="text-base font-semibold leading-7 text-gray-900">
              Neues Teammitglied erstellen
            </h2>
          </div>

          <form
            onSubmit={handleSubmit}
            className="bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-2"
          >
            <div className="px-4 py-6 sm:p-8">
              {/* Display error message */}
              {error && (
                <div className="sm:max-w-md">
                  <Alert severity="error" className=" mb-4">
                    {error}
                  </Alert>
                </div>
              )}

              <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                <div className="sm:col-span-4">
                  <label
                    htmlFor="website"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Vollständiger Name <span className="text-red-500">*</span>
                  </label>
                  <div className="mt-2">
                    <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                      <input
                        required
                        type="text"
                        placeholder="Gib den vollständigen Namen des Mitarbeiters ein"
                        className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                        value={fullName}
                        onChange={(e) => setFullName(e.target.value)}
                      />
                    </div>
                  </div>
                </div>

                <div className="sm:col-span-4">
                  <label
                    htmlFor="website"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Anzeigename <span className="text-red-500">*</span>
                  </label>
                  <div className="mt-2">
                    <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                      <input
                        required
                        type="text"
                        placeholder="Gib den Anzeigenamen des Mitarbeiters ein"
                        className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                        value={displayName}
                        onChange={(e) => setDisplayName(e.target.value)}
                      />
                    </div>
                  </div>
                </div>

                <div className="sm:col-span-4">
                  <label
                    htmlFor="website"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    E-Mail <span className="text-red-500">*</span>
                  </label>
                  <div className="mt-2">
                    <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                      <input
                        required
                        type="text"
                        placeholder="Gib die E-Mail-Adresse des Mitarbeiters ein"
                        className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </div>
                  </div>
                </div>

                <div className="sm:col-span-4">
                  <div>
                    <label
                      htmlFor="location"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Rolle <span className="text-red-500">*</span>
                    </label>
                    <select
                      id="role"
                      name="role"
                      required
                      className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      defaultValue="agent"
                      value={newUserRole}
                      onChange={(e) => setNewUserRole(e.target.value)}
                    >
                      <option value="agent">{capitalizeWords('agent')}</option>
                      {role === 'admin' && (
                        <>
                          <option value="team leader">Teamleiter</option>
                          <option value="customer manager">
                            Kundenmanagement
                          </option>
                          <option value="admin">
                            {capitalizeWords('admin')}
                          </option>
                        </>
                      )}
                    </select>
                  </div>
                </div>
              </div>
            </div>

            <div className="flex items-center justify-end gap-x-6 border-t border-gray-900/10 px-4 py-4 sm:px-8">
              <Link
                to="/agents"
                className="text-sm font-semibold leading-6 text-gray-900"
              >
                Abbrechen
              </Link>
              <button
                type="submit"
                disabled={isPending}
                className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                {isPending ? 'Wird gespeichert...' : 'Speichern'}
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
