import { createSlice } from "@reduxjs/toolkit";

const initialState = {
 missedCalls: []
}

const missedCallSlice = createSlice({
  name: 'missedCall',
  initialState,
  reducers: {
    addMissedCall(state, action) {
      // Add the missed call to missedCalls array
      state.missedCalls = [...state.missedCalls, action.payload]
    },
    removeMissedCall(state, action) {
      // see if we can use id or if we need to use roomSid
      state.missedCalls = state.missedCalls.filter(call => call.roomSid !== action.payload.roomSid);
    }
  }
});

export const { addMissedCall, removeMissedCall } = missedCallSlice.actions
export default missedCallSlice.reducer;


