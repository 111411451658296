import { useCollection } from '../../hooks/useCollection';
import { useLocation } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { useAuthContext } from '../../hooks/useAuthContext';
import { Alert } from '@mui/material';

// styles, icons & components
import PageHeader from '../../components/pageheader/PageHeader';
import ClientList from '../../components/ClientList/ClientList';
import SuccessAlert from '../../components/successalert/SuccessAlert';

export default function Clients() {
  const { documents, error, isPending } = useCollection('clients');
  const location = useLocation();
  const [openSuccessAlert, setOpenSuccessAlert] = useState(
    location.state?.deletionSuccess || false,
  );
  // sort the clients so that newest appears first
  const sortedClients = documents
    ? documents.sort((a, b) => b.createdAt - a.createdAt)
    : [];
  const { role } = useAuthContext();

  // use effect hook so that the success alert is closed by clicking anywhere
  useEffect(() => {
    const closeAlert = () => setOpenSuccessAlert(false);
    if (openSuccessAlert) {
      window.addEventListener('click', closeAlert);
    }
    return () => {
      window.removeEventListener('click', closeAlert);
    };
  }, [openSuccessAlert]);

  // use effect hook to make the success alert close by itself after a few seconds
  useEffect(() => {
    if (openSuccessAlert) {
      const timer = setTimeout(() => {
        setOpenSuccessAlert(false);
      }, 2000); // 2000 milliseconds = 2 seconds
      return () => clearTimeout(timer);
    }
  }, [openSuccessAlert]);

  return (
    <div>
      {isPending && <div>Kunden werden geladen...</div>}
      <PageHeader
        title="Kunden"
        href="/newclient"
        addButtonText="Kunde"
        role={role}
      />
      {error && (
        <Alert severity="error">
          Fehler: Daten konnten nicht geladen werden.
        </Alert>
      )}
      {openSuccessAlert && (
        <SuccessAlert
          open={openSuccessAlert}
          close={() => setOpenSuccessAlert(false)}
          text="Erfolgreich gelöscht"
        ></SuccessAlert>
      )}
      {documents && <ClientList clients={sortedClients} />}
    </div>
  );
}
