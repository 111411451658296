import { Link } from 'react-router-dom';
import { useFirestore } from '../../hooks/useFirestore';
import { useState, useEffect, useRef } from 'react';
import { useTwilioVideo } from '../../hooks/useTwilioVideo';
import { useAuthContext } from '../../hooks/useAuthContext';
import { useDocument } from '../../hooks/useDocument';
import { doc, setDoc, getDoc } from 'firebase/firestore';
import { db } from '../../firebase/config';
import { useSocketContext } from '../../hooks/useSocketContext';
import { useTwilioTracks } from '../../hooks/useTwilioTracks';
import { Alert } from '@mui/material';

// styles & icons
import {
  PencilIcon,
  TrashIcon,
  PhoneArrowUpRightIcon,
} from '@heroicons/react/20/solid';

// components
import DeleteAlert from '../deletealert/DeleteAlert';
import SuccessAlert from '../successalert/SuccessAlert';
import VideoCall from '../videocall/VideoCall';
import NotificationDialog from '../notificationdialog/NotificationDialog';

export default function LocationList({
  locations,
  clientDocument,
  clientId,
  role,
}) {
  const { deleteDocument, response: deleteResponse } =
    useFirestore('locations');
  const { updateDocument, response: updateResponse } = useFirestore('clients');
  const [openDeleteLocationAlert, setOpenDeleteLocationAlert] = useState(false);
  const [openSuccessAlert, setOpenSuccessAlert] = useState(false);
  const [callType, setCallType] = useState(null);
  const { joinRoom, room } = useTwilioVideo();
  const { user } = useAuthContext();
  const [showVideo, setShowVideo] = useState(false);
  const { document: userDocument } = useDocument('users', user.uid);
  const socket = useSocketContext();
  const localVideoRef = useRef();
  const remoteVideoRef = useRef();
  const {
    detachLocalTracks,
    attachSubscribedTracks,
    disconnectRoom,
    attachLocalVideoTrack,
    attachLocalAudioTrack,
  } = useTwilioTracks(room, remoteVideoRef, localVideoRef);
  const [openEndCallDialog, setOpenEndCallDialog] = useState(false);
  const [roomError, setRoomError] = useState(null);
  const [openNotificationDialog, setOpenNotificationDialog] = useState(false);
  const [locationToDelete, setLocationToDelete] = useState(null);
  const [deleteError, setDeleteError] = useState(null);
  const [updateError, setUpdateError] = useState(null);

  // use effect hook so that the success alert is closed by clicking anywhere
  useEffect(() => {
    const closeAlert = () => setOpenSuccessAlert(false);
    if (openSuccessAlert) {
      window.addEventListener('click', closeAlert);
    }
    return () => {
      window.removeEventListener('click', closeAlert);
    };
  }, [openSuccessAlert]);

  // use effect hook to make the success alert close by itself after a few seconds
  useEffect(() => {
    if (openSuccessAlert) {
      const timer = setTimeout(() => {
        setOpenSuccessAlert(false);
      }, 2000); // 2000 milliseconds = 2 seconds
      return () => clearTimeout(timer);
    }
  }, [openSuccessAlert]);

  // function to save outgoing calls to the db
  const saveOutgoingCall = async (room) => {
    const outgoingCall = {
      roomName: room.name,
      callStarted: new Date().toISOString(),
      agent: userDocument.displayName,
      completed: false,
    };

    const outgoingCallDocRef = doc(db, 'outgoingCalls', room.sid);

    try {
      await setDoc(outgoingCallDocRef, outgoingCall); // Set the document at the specified reference
      return outgoingCallDocRef; // Return the document reference
    } catch (e) {
      console.error('Error adding document: ', e);
    }
  };

  // useEffect hook to listen for room creation and attach tracks/show video interface if so
  useEffect(() => {
    if (room) {
      attachLocalVideoTrack();
      attachLocalAudioTrack();
      // Listen for remote participant to join the room, then subscribe to remote tracks
      room.on('participantConnected', (participant) => {
        participant.on('trackSubscribed', (track) => {
          attachSubscribedTracks(track);
        });
      });

      // Detach the local media elements (takes place when either agent or user disconnects call)
      room.on('disconnected', (room) => {
        detachLocalTracks();
      });

      // Disconnect the room if the agent ends the call
      room.on('participantDisconnected', (participant) => {
        disconnectRoom();
        setShowVideo(false);
        setOpenEndCallDialog(true);
      });
    }
  }, [
    room,
    attachLocalAudioTrack,
    attachLocalVideoTrack,
    attachSubscribedTracks,
    detachLocalTracks,
    disconnectRoom,
  ]);

  const handleDelete = async (clientDocument, locationId) => {
    setDeleteError(null);

    try {
      // Get the current locations array of the client
      const currentLocations = clientDocument.locations;

      // Get the index within the array of the location to be deleted
      const locationIndex = currentLocations.indexOf(locationId);

      // Check that the location index was found in the array
      if (locationIndex !== -1) {
        // Create a copy of the array without the loc id to be deleted
        const updatedLocations = currentLocations.slice(0);

        // Remove the loc id to be deleted from the copied array
        updatedLocations.splice(locationIndex, 1);

        // Update the client doc with the new array
        await updateDocument(clientId, {
          locations: updatedLocations,
        });

        if (updateResponse.error) {
          console.error('updateResponse.error:', updateResponse.error);
          setUpdateError('Ein unerwarteter Fehler ist aufgetreten.');
        }
      }

      // Once client document has been updated, delete location document
      await deleteDocument(locationId);
      if (deleteResponse.error) {
        console.error('deleteResponse.error');
        setDeleteError('Fehler: Standort konnte nicht gelöscht werden.');
        return;
      } else {
        setOpenSuccessAlert(true);
      }
    } catch (error) {
      setDeleteError('Fehler: Standort konnte nicht gelöscht werden.');
      console.error('Delete error:', error);
    } finally {
      setOpenDeleteLocationAlert(false);
      setLocationToDelete(null); // Reset the locationToDelete state
    }
  };

  // pass location as arg to the function so we can access name and address properties as we iterate through in component below)
  const handleCall = async (location) => {
    try {
      // set call type for saving to db on call end (in video buttons component)
      setCallType('outgoing');

      // construct the room name
      const roomName = `${location.name}-${location.address}`;

      // create and join a Twilio room
      const newRoom = await joinRoom(user.uid, roomName);

      // show video component
      setShowVideo(true);

      // Save outgoingCall record to the db and get ref
      const outgoingCallRef = await saveOutgoingCall(newRoom);

      // Fetch the newly created record from the db
      const outgoingCallDoc = await getDoc(outgoingCallRef);

      // Check path to the db record exists, then emit socket event with relevant data
      if (outgoingCallDoc.exists()) {
        const outgoingCallData = outgoingCallDoc.data();
        const documentId = outgoingCallDoc.id;
        if (socket) {
          const outgoingRoom = {
            roomName: outgoingCallData.roomName,
            roomSid: documentId,
            agent: outgoingCallData.agent,
            timestamp: new Date().toISOString(),
          };
          socket.emit('outgoing-call', location.id, outgoingRoom);
        }
      }
    } catch (error) {
      console.error('Error connecting to room:', error);
      setRoomError(error.message);
      setOpenNotificationDialog(true);
    }
  };

  const handleCloseEndCallDialog = () => {
    setOpenEndCallDialog(false);
  };

  const handleCloseNotificationDialog = () => {
    setOpenNotificationDialog(false);
    setRoomError(null);
  };

  return (
    <>
      {deleteError && (
        <Alert severity="error" className="mt-8">
          {deleteError}
        </Alert>
      )}
      {updateError && (
        <Alert severity="error" className="mt-8">
          {updateError}
        </Alert>
      )}
      {openSuccessAlert && (
        <SuccessAlert
          open={openSuccessAlert}
          close={() => setOpenSuccessAlert(false)}
          text="Erfolgreich gelöscht"
        ></SuccessAlert>
      )}
      {showVideo && (
        <VideoCall
          localVideoRef={localVideoRef}
          remoteVideoRef={remoteVideoRef}
          room={room}
          callType={callType}
          showVideo={showVideo}
          setShowVideo={setShowVideo}
          isAgent={true}
        />
      )}
      {openEndCallDialog && (
        <NotificationDialog
          open={openEndCallDialog}
          title="Anruf beendet"
          text="Kunde hat den Anruf beendet"
          close={handleCloseEndCallDialog}
        />
      )}
      {openNotificationDialog && (
        <NotificationDialog
          open={openNotificationDialog}
          title="Anruf läuft mit einem anderen Agenten"
          text={roomError}
          close={handleCloseNotificationDialog}
        />
      )}
      <div className="mt-10 grid grid-cols-1 gap-4 sm:grid-cols-2">
        {locations.length === 0 && <p>Noch keine Standorte!</p>}
        {locations.map((location) => (
          <div
            key={location.id}
            className="relative flex items-center space-x-3 rounded-lg border border-gray-300 bg-white px-6 py-5 shadow-sm focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 hover:border-gray-400"
          >
            <div className="min-w-0 flex-1">
              <Link
                to={`/clients/${clientId}/locations/${location.id}`}
                target="_blank"
                className="focus:outline-none"
              >
                <p className="text-sm font-medium text-gray-900">
                  {location.name}
                </p>
                <p className="truncate text-sm text-gray-500">
                  {location.address}
                </p>
              </Link>
            </div>
            <div className="ml-4 flex flex-shrink-0 items-center">
              {/* Call Icon */}
              {role !== 'customer manager' && (
                <button
                  className="ml-2 text-gray-400 hover:text-gray-600"
                  onClick={() => handleCall(location)}
                >
                  <PhoneArrowUpRightIcon
                    className="h-5 w-5"
                    aria-hidden="true"
                  />
                </button>
              )}
              {/* Edit & Delete Icons */}
              {role !== 'agent' && role !== 'team leader' && (
                <>
                  <Link
                    to={`/clients/${clientId}/locations/${location.id}/edit`}
                    className="ml-3 text-gray-400 hover:text-gray-600"
                  >
                    <PencilIcon className="h-5 w-5" aria-hidden="true" />
                  </Link>
                  <button
                    className="ml-2 text-gray-400 hover:text-gray-600"
                    onClick={() => {
                      setLocationToDelete(location);
                      setOpenDeleteLocationAlert(true);
                    }}
                  >
                    <TrashIcon className="h-5 w-5" aria-hidden="true" />
                  </button>
                </>
              )}
              {openDeleteLocationAlert &&
                locationToDelete?.id === location.id && (
                  <DeleteAlert
                    open={openDeleteLocationAlert}
                    close={() => setOpenDeleteLocationAlert(false)}
                    handleDelete={async () =>
                      handleDelete(clientDocument, location.id)
                    }
                    title={`${location.name} löschen`}
                    text={`Bist du sicher, dass du ${location.name} dauerhaft löschen möchtest?`}
                  ></DeleteAlert>
                )}
            </div>
          </div>
        ))}
      </div>
    </>
  );
}

// const handleDelete = async (clientDocument, locationId) => {
//   setDeleteError(null);
//   // get the locations array of the client
//   const currentLocations = clientDocument.locations;

//   // get the index within the array of the location to be deleted
//   const locationIndex = currentLocations.indexOf(locationId);

//   // check that the location index was found in the array
//   if (locationIndex !== -1) {
//     // create a copy of the array without the loc id to be deleted
//     const updatedLocations = currentLocations.slice(0);

//     // remove the loc id to be deleted from the copied array
//     updatedLocations.splice(locationIndex, 1);

//     // update the client doc with the new array
//     await updateDocument(clientId, {
//       locations: updatedLocations,
//     });

//     console.log('updateResponse:', updateResponse);
//   }

//   // once client document has been updated, delete location document
//   await deleteDocument(locationId);
//   console.log('deleteResponse 1st log:', deleteResponse);
//   console.log('deleteResonse.status 1st log:', deleteResponse.status);
//   if (deleteResponse.error || deleteResponse.status !== 200) {
//     console.log('deleteResponse 2nd log:', deleteResponse);
//     console.log('deleteResponse.status 2nd log:', deleteResponse.status);
//     setDeleteError('Fehler: Standort konnte nicht gelöscht werden.');
//     console.log('delete error set');
//   } else {
//     setOpenSuccessAlert(true);
//   }
//   setOpenDeleteLocationAlert(false);
//   setLocationToDelete(null); // Reset the locationToDelete state
// };
