import { createContext, useEffect, useState } from 'react';
import io from 'socket.io-client';
import { useDispatch } from 'react-redux';
import {
  initiateCall,
  acceptCall,
  cancelCall,
} from '../features/calls/callSlice';
import {
  addMissedCall,
  removeMissedCall,
} from '../features/calls/missedCallSlice';
// import { useAuthContext } from '../hooks/useAuthContext';
import OracomLogo from '../assets/logo_oracom.jpg';

export const SocketContext = createContext();

export const SocketProvider = ({ children }) => {
  const [socket, setSocket] = useState(null);
  const dispatch = useDispatch();
  // const { userProfile } = useAuthContext();
  const logoUrl = OracomLogo;

  useEffect(() => {
    // Establish socket connection
    // const newSocket = io('http://localhost:3001');
    const newSocket = io('https://api.oracom.de/');
    setSocket(newSocket);

    // Define all socket event listeners here
    newSocket.on('incoming-call', (newRoom) => {
      dispatch(initiateCall(newRoom));
      // Check if browser supports notifications
      if ('Notification' in window && Notification.permission === 'granted') {
        new Notification('Incoming Call', {
          body: 'You have an incoming call.',
          icon: logoUrl,
        });
      } else if (Notification.permission === 'default') {
        // If permission has not been denied, but not granted yet
        Notification.requestPermission().then((permission) => {
          if (permission === 'granted') {
            new Notification('Incoming Call', {
              body: 'You have an incoming call.',
              icon: logoUrl,
            });
          }
        });
      }
    });

    newSocket.on('call-accepted', (acceptedRoom) => {
      // Dispatch the 'acceptCall' action with the  new room data
      dispatch(acceptCall(acceptedRoom));
    });

    newSocket.on('call-declined', (cancelledRoom) => {
      // dispatch the cancelCall action so that notification is removed if call cancelled
      dispatch(cancelCall(cancelledRoom));
      // dispatch event to add this room to the missed calls array - contains roomName, roomSid(same
      // as the id of the incoming call doc), and timestamp...
      dispatch(addMissedCall(cancelledRoom));
    });

    newSocket.on('call-cancelled', (cancelledRoom) => {
      dispatch(cancelCall(cancelledRoom));
    });

    newSocket.on('call-timedout', (timedoutRoom) => {
      // Dispatch action to remove call from incomingCalls array
      dispatch(cancelCall(timedoutRoom));

      // Dispatch action to add call to missedCalls array
      dispatch(addMissedCall(timedoutRoom));
    });

    newSocket.on('remove-missedcall', (outgoingRoom) => {
      dispatch(removeMissedCall(outgoingRoom));
    });

    // Clean up the connection when the provider unmounts
    // Note: deleted this from the return: userProfile?.pushNotificationsEnabled
    return () => newSocket.disconnect();
  }, [dispatch, logoUrl]);

  return (
    <SocketContext.Provider value={socket}>{children}</SocketContext.Provider>
  );
};
