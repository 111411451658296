import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  getAuth,
  isSignInWithEmailLink,
  signInWithEmailLink,
  updatePassword,
} from 'firebase/auth';
import { useAuthContext } from '../../hooks/useAuthContext';
import { Alert } from '@mui/material';

import OracomLogo from '../../assets/oracom_logo_text.png';
import { EyeIcon, EyeSlashIcon } from '@heroicons/react/24/outline';

export default function FinishSignup() {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordsMatch, setPasswordsMatch] = useState(true);
  const [email, setEmail] = useState('');
  const [isPending, setIsPending] = useState(false);
  const navigate = useNavigate();
  const { dispatch } = useAuthContext();
  const [showChoosePassword, setShowChoosePassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const auth = getAuth();
    if (!isSignInWithEmailLink(auth, window.location.href)) {
      navigate('/login');
    }
  }, [navigate]);

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
    setPasswordsMatch(true); // Reset match status when confirmation changes
  };

  const handleFinishSignup = async (e) => {
    e.preventDefault();
    setError(null);
    if (password !== confirmPassword) {
      setPasswordsMatch(false);
      return;
    }
    setIsPending(true);
    try {
      const auth = getAuth();

      // Complete the sign-in process
      const userCredential = await signInWithEmailLink(
        auth,
        email,
        window.location.href,
      );
      await updatePassword(userCredential.user, password);

      // Fetch the role
      const idTokenResult = await userCredential.user.getIdTokenResult();
      const role = idTokenResult.claims.role;

      // Dispatch login action, now with the role too
      dispatch({ type: 'LOGIN', payload: { user: userCredential.user, role } });

      // Redirect to dashboard or another page
      navigate('/');
    } catch (error) {
      // Handle sign up errors
      let errorMessage = 'Fehler: Ein Fehler ist aufgetreten.';
        switch (error.code) {
          case 'auth/invalid-email':
            errorMessage = 'Fehler: Die E-Mail-Adresse ist ungültig.';
            break;
          case 'auth/user-disabled':
            errorMessage = 'Fehler: Dieses Konto wurde deaktiviert.';
            break;
          case 'auth/user-not-found':
            errorMessage =
              'Fehler: Kein Benutzer mit dieser E-Mail-Adresse gefunden.';
            break;
          case 'auth/weak-password':
            errorMessage = 'Fehler: Das Passwort ist zu schwach.';
            break;
          case 'auth/email-already-exists':
            errorMessage =
              'Fehler: Diese E-Mail-Adresse wird bereits verwendet.';
            break;
          default:
            errorMessage = 'Fehler: Ein Fehler ist aufgetreten.';
        }
      setError(errorMessage);
    } finally {
      setIsPending(false);
    }
  };

  return (
    <>
      <div className="flex min-h-full flex-1 flex-col justify-center py-12 sm:px-6 lg:px-8">
        {/* div with logo and invitation to complete sign up*/}
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <img
            className="mx-auto h-10 w-auto"
            src={OracomLogo}
            alt="Oracom Logo"
          />
          <h2 className="mt-6 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
            Registrierungsprozess abschließen
          </h2>
        </div>

        <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-[480px]">
          <div className="bg-white px-6 py-12 shadow sm:rounded-lg sm:px-12">
            <form className="space-y-6" onSubmit={handleFinishSignup}>
              {/* show errors here */}
              {!passwordsMatch && (
                <Alert severity="error">
                  Passwörter stimmen nicht überein.
                </Alert>
              )}
              {error && <Alert severity="error">{error}</Alert>}
              {/* div with email input */}
              <div>
                <label className="block text-sm font-medium leading-6 text-gray-900">
                  E-Mail eingeben: <span className="text-red-500">*</span>
                </label>
                <div className="mt-2">
                  <input
                    required
                    type="email"
                    onChange={(e) => setEmail(e.target.value)}
                    value={email}
                    placeholder="Gib deine E-Mail-Adresse ein"
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>

              {/* div with password input */}
              <div>
                <label className="block text-sm font-medium leading-6 text-gray-900">
                  Wähle ein Passwort: <span className="text-red-500">*</span>
                </label>
                <div className="relative mt-2">
                  <input
                    required
                    type={showChoosePassword ? 'text' : 'password'}
                    onChange={(e) => setPassword(e.target.value)}
                    placeholder="Passwörter sollten mindestens 6 Zeichen enthalten"
                    value={password}
                    minLength="6"
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                  <button
                    type="button"
                    onClick={() => setShowChoosePassword(!showChoosePassword)}
                    className="absolute inset-y-0 right-0 flex items-center px-2"
                  >
                    {showChoosePassword ? (
                      <EyeSlashIcon className="h-5 w-5 text-gray-500" />
                    ) : (
                      <EyeIcon className="h-5 w-5 text-gray-500" />
                    )}
                  </button>
                </div>
              </div>

              {/* div with confirm password input */}
              <div>
                <label className="block text-sm font-medium leading-6 text-gray-900">
                  Passwort bestätigen: <span className="text-red-500">*</span>
                </label>
                <div className="relative mt-2">
                  <input
                    required
                    type={showConfirmPassword ? 'text' : 'password'}
                    onChange={handleConfirmPasswordChange}
                    placeholder="Passwörter sollten mindestens 6 Zeichen enthalten"
                    value={confirmPassword}
                    minLength="6"
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                  <button
                    type="button"
                    onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                    className="absolute inset-y-0 right-0 flex items-center px-2"
                  >
                    {showConfirmPassword ? (
                      <EyeSlashIcon className="h-5 w-5 text-gray-500" />
                    ) : (
                      <EyeIcon className="h-5 w-5 text-gray-500" />
                    )}
                  </button>
                </div>
              </div>

              {/* div for sign in button */}
              <div>
                <button
                  disable={isPending}
                  className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  {isPending ? 'Anmeldung...' : 'Registrierung abschließen'}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

// old handleFinishSignup function:
// const handleFinishSignup = async (e) => {
//   e.preventDefault();
//   if (password !== confirmPassword) {
//     setPasswordsMatch(false);
//     return;
//   }
//   setIsPending(true);
//   try {
//     const auth = getAuth();

//     // Complete the sign-in process
//     const userCredential = await signInWithEmailLink(
//       auth,
//       email,
//       window.location.href,
//     );
//     await updatePassword(userCredential.user, password);

//     dispatch({ type: 'LOGIN', payload: userCredential.user });

//     // Redirect to dashboard or another page
//     navigate('/');
//     setIsPending(false);
//   } catch (error) {
//     // Handle errors such as invalid link, etc.
//     console.error('Error finishing sign-up:', error);
//   }
// };
