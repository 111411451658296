import { useEffect, useState } from 'react'
import { db } from '../firebase/config'

// firebase imports
import { doc, onSnapshot } from 'firebase/firestore'

export const useDocument = (collection, id) => {
  const [document, setDocument] = useState(null)
  const [error, setError] = useState(null)

  // fetch real time document data
  useEffect(() => {
    const ref = doc(db, collection, id)

    const unsubscribe = onSnapshot(ref, (snapshot) => {
      if (snapshot.data()) {
        setDocument({ ...snapshot.data(), id: snapshot.id })
        setError(null)
      } else {
        setError('Fehler: Solche Daten existieren nicht.')
      }
    }, (err) => {
      console.error(err.message)
      setError('Fehler: Daten konnten nicht abgerufen werden.')
    })

    // unsubscribe on unmount
    return () => unsubscribe()
  }, [collection, id])

  return { document, error }
}
