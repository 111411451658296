import { useCollection } from '../../hooks/useCollection';
import { Link, useLocation } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { useAuthContext } from '../../hooks/useAuthContext';
import { Alert } from '@mui/material';

// components
import SuccessAlert from '../../components/successalert/SuccessAlert';

// icons
import { PlusSmallIcon } from '@heroicons/react/20/solid';
import { EnvelopeIcon } from '@heroicons/react/20/solid';

export default function Agents() {
  const { documents, error, isPending } = useCollection('users');
  const location = useLocation();
  const [openSuccessAlert, setOpenSuccessAlert] = useState(
    location.state?.deletionSuccess || false,
  );
  const { role } = useAuthContext();
  const [activeTab, setActiveTab] = useState('All');

  // use effect hook so that the success alert is closed by clicking anywhere
  useEffect(() => {
    const closeAlert = () => setOpenSuccessAlert(false);
    if (openSuccessAlert) {
      window.addEventListener('click', closeAlert);
    }
    return () => {
      window.removeEventListener('click', closeAlert);
    };
  }, [openSuccessAlert]);

  // use effect hook to make the success alert close by itself after a few seconds
  useEffect(() => {
    if (openSuccessAlert) {
      const timer = setTimeout(() => {
        setOpenSuccessAlert(false);
      }, 2000); // 2000 milliseconds = 2 seconds
      return () => clearTimeout(timer);
    }
  }, [openSuccessAlert]);

  // Filter users based on availability
  const onlineUsers = documents?.filter((user) => user.available);

  const offlineUsers = documents?.filter((user) => !user.available);

  // Function to determine the color class based on the role
  const getColorClass = (role) => {
    switch (role) {
      case 'admin':
        return 'bg-blue-50 text-blue-700 ring-blue-600/20';
      case 'team leader':
        return 'bg-yellow-50 text-yellow-700 ring-yellow-600/20';
      case 'customer manager':
        return 'bg-purple-50 text-purple-700 ring-purple-600/20';
      case 'agent':
        return 'bg-green-50 text-green-700 ring-green-600/20';
      default:
        return 'bg-gray-50 text-gray-700 ring-gray-600/20';
    }
  };

  // Function to translate the roles to German
  const translateUserRole = (role) => {
    switch (role) {
      case 'admin':
        return 'Admin';
      case 'team leader':
        return 'Teamleiter';
      case 'customer manager':
        return 'Kundenmanagement';
      case 'agent':
        return 'Agent';
      default:
        return role;
    }
  };

  const renderUsers = (users) => {
    if (!users) {
      return null;
    }

    return (
      <ul className="mt-10 grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
        {users.map((user) => (
          <li
            key={user.id}
            className="col-span-1 flex flex-col divide-y divide-gray-200 rounded-lg bg-white text-center shadow"
          >
            <Link to={`/agents/${user.id}`}>
              <div className="flex flex-1 flex-col p-8">
                <div className="relative mx-auto inline-block">
                  <img
                    className="mx-auto h-32 w-32 flex-shrink-0 rounded-full object-cover"
                    src={user.photoURL}
                    alt=""
                  />
                  <span
                    className={`absolute right-0 top-0 block h-4 w-4 translate-x-[-17px] rounded-full ring-2 ring-white ${user.available ? 'bg-green-400' : 'bg-gray-400'}`}
                  />
                </div>
                <h3 className="mt-6 text-sm font-medium text-gray-900">
                  {user.fullName}
                </h3>
                <dl className="mt-1 flex flex-grow flex-col justify-between">
                  <dd className="text-sm text-gray-500"></dd>
                  <dt className="sr-only">Rolle</dt>
                  <dd className="mt-3">
                    <span
                      className={`inline-flex items-center rounded-full px-2 py-1 text-xs font-medium ring-1 ring-inset ${getColorClass(user.role)}`}
                    >
                      {translateUserRole(user.role)}
                    </span>
                  </dd>
                </dl>
              </div>
            </Link>
            <div>
              <div className="-mt-px flex divide-x divide-gray-200">
                <div className="flex w-0 flex-1">
                  <a
                    href={`mailto:${user.email}`}
                    // href={`mailto:${person.email}`}
                    className="relative -mr-px inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-bl-lg border border-transparent py-4 text-sm font-semibold text-gray-900"
                  >
                    <EnvelopeIcon
                      className="h-5 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                    E-Mail
                  </a>
                </div>
              </div>
            </div>
          </li>
        ))}
      </ul>
    );
  };

  return (
    <>
      <div className="border-b border-gray-200 md:flex md:items-center md:justify-between md:space-x-5">
        <div class="sm:flex sm:items-baseline">
          <button onClick={() => setActiveTab('All')}>
            <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">
              Team
            </h2>
          </button>
          <div class="mt-4 sm:ml-10 sm:mt-0">
            <nav class="-mb-px flex space-x-8">
              <button
                onClick={() => setActiveTab('Online')}
                class={`border-b-2 px-1 pb-4 text-sm font-medium ${activeTab === 'Online' ? 'border-indigo-500 text-indigo-600' : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700'}`}
                aria-current="page"
              >
                Online
              </button>
              <button
                onClick={() => setActiveTab('Offline')}
                class={`border-b-2 px-1 pb-4 text-sm font-medium ${activeTab === 'Offline' ? 'border-indigo-500 text-indigo-600' : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700'}`}
              >
                Offline
              </button>
            </nav>
          </div>
        </div>

        {role !== 'agent' && role !== 'customer manager' && (
          <div className="mt-4 flex md:ml-4 md:mt-0">
            <Link
              to="/newagent"
              className="mb-4 ml-3 inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              <PlusSmallIcon className="-ml-1.5 h-5 w-5" aria-hidden="true" />
              Neues Teammitglied
            </Link>
          </div>
        )}
      </div>
      {openSuccessAlert && (
        <SuccessAlert
          open={openSuccessAlert}
          close={() => setOpenSuccessAlert(false)}
          text="User successfully deleted"
        ></SuccessAlert>
      )}
      {isPending && <div>Wird geladen...</div>}
      {error && (
        <Alert severity="error" className="mt-2">
          Fehler: Daten konnten nicht geladen werden.
        </Alert>
      )}
      {activeTab === 'All' && renderUsers(documents)}
      {activeTab === 'Online' && renderUsers(onlineUsers)}
      {activeTab === 'Offline' && renderUsers(offlineUsers)}
    </>
  );
}
