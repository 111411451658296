import { useDocument } from '../../hooks/useDocument';
import { useParams } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import { useFirestore } from '../../hooks/useFirestore';
import io from 'socket.io-client';
import { useTwilioVideo } from '../../hooks/useTwilioVideo';
import { useTwilioTracks } from '../../hooks/useTwilioTracks';
import { doc, setDoc, getDoc } from 'firebase/firestore';
import { db } from '../../firebase/config';
import { useDispatch } from 'react-redux';
// import { serverTimestamp } from 'firebase/firestore';
import { useCollection } from '../../hooks/useCollection';

// components
import VideoCall from '../../components/videocall/VideoCall';
import CallPending from '../../components/callpending/CallPending';
import NotificationDialog from '../../components/notificationdialog/NotificationDialog';

// styles, icons & images
import { PhoneArrowUpRightIcon } from '@heroicons/react/20/solid';

export default function Location() {
  const { documents: users } = useCollection('users');
  const { clientId, locationId } = useParams();
  const { document: clientDocument } = useDocument('clients', clientId);
  const { document: locationDocument } = useDocument('locations', locationId);
  const { updateDocument: updateIncomingCallDocument } =
    useFirestore('incomingCalls');
  const remoteVideoRef = useRef();
  const [showVideo, setShowVideo] = useState(null);
  const [callPending, setCallPending] = useState(false);
  const [openEndCallDialog, setOpenEndCallDialog] = useState(false);
  const [openNoAvailableAgentsDialog, setOpenNoAvailableAgentsDialog] =
    useState(false);
  const { joinRoom, room, error } = useTwilioVideo();
  const {
    detachLocalTracks,
    attachSubscribedTracks,
    disconnectRoom,
    attachLocalAudioTrack,
    setupTrackEventHandlers,
  } = useTwilioTracks(room, remoteVideoRef, null);
  const socketRef = useRef(null);
  const [roomNameToJoin, setRoomNameToJoin] = useState('');
  const [callType, setCallType] = useState(null);
  const dispatch = useDispatch();
  const [isAgentCameraOn, setIsAgentCameraOn] = useState(true);
  const [isAgentMicOn, setIsAgentMicOn] = useState(true);
  const availableAgents = users?.filter(user => user.available && user.role === 'agent');

  useEffect(() => {
    // Connect to your Socket.io server
    const newSocket = io('https://api.oracom.de'); // Replace with your production server URL
    // const newSocket = io('http://localhost:3001')
    // setSocket(newSocket)
    socketRef.current = newSocket;

    const updateCallRecord = async (id, updates) => {
      try {
        await updateIncomingCallDocument(id, updates);
      } catch (e) {
        console.error('Error updating call record:', e);
      }
    };

    // Join a specific socket io room based on the locationId from the URL params
    if (locationId) {
      socketRef.current.emit('joinRoom', locationId);
    }

    newSocket.on('outgoing-call', (newRoom) => {
      // Logic to join the room or handle the call
      if (roomNameToJoin !== newRoom.roomName) {
        setRoomNameToJoin(newRoom.roomName);
      }
    });

    newSocket.on('call-timedout-at-location', (timedoutRoom) => {
      room.disconnect();
      const updates = {
        actionType: 'missed',
        actionTimestamp: new Date().toISOString(),
        agent: '',
        completed: true,
      };
      updateCallRecord(timedoutRoom.roomSid, updates);
      setCallPending(false);
      setOpenNoAvailableAgentsDialog(true);
    });

    newSocket.on('call-declined-at-location', (declinedRoom) => {
      if (room) {
        room.disconnect();
        const updates = {
          actionType: 'missed',
          actionTimestamp: new Date().toISOString(),
          agent: '',
          completed: true,
        };
        updateCallRecord(declinedRoom.roomSid, updates);
        setCallPending(false);
        setOpenNoAvailableAgentsDialog(true);
      }
    });

    return () => {
      // Clean up the socket connection when the component unmounts
      newSocket.disconnect();
    };
  }, [room, locationId, roomNameToJoin, dispatch, updateIncomingCallDocument]);

  useEffect(() => {
    if (roomNameToJoin) {
      const joinOutgoingCall = async () => {
        try {
          await joinRoom(locationId, roomNameToJoin);
          setCallType('outgoing');
          setRoomNameToJoin('');
        } catch (error) {
          console.error(error);
        }
      };
      joinOutgoingCall();
    }
  }, [locationId, joinRoom, roomNameToJoin]);

  useEffect(() => {
    if (locationDocument && locationDocument.color) {
      // Set the background color on the body element
      document.body.style.backgroundColor = locationDocument.color;
    }
    // Cleanup function
    return () => {
      // Reset the background color when the component unmounts
      document.body.style.backgroundColor = '';
    };
  }, [locationDocument]);

  useEffect(() => {
    if (room) {
      const onVideoDisabled = () => setIsAgentCameraOn(false);
      const onVideoEnabled = () => setIsAgentCameraOn(true);
      const onAudioDisabled = () => setIsAgentMicOn(false);
      const onAudioEnabled = () => setIsAgentMicOn(true);

      if (callType === 'incoming') {
        attachLocalAudioTrack();

        // When the other participant accepts the call and joins room, log this and attach their
        // video and audio tracks
        room.on('participantConnected', (participant) => {
          participant.on('trackSubscribed', (track) => {
            attachSubscribedTracks(track);
          });
          setCallPending(false);
          setShowVideo(true);
        });
      }

      if (callType === 'outgoing') {
        attachLocalAudioTrack();
        room.participants.forEach((participant) => {
          participant.on('trackSubscribed', (track) => {
            attachSubscribedTracks(track);
          });
        });
        setShowVideo(true);
      }

      // Attach handlers for existing participants
      room.participants.forEach((participant) => {
        setupTrackEventHandlers(
          participant,
          onVideoDisabled,
          onVideoEnabled,
          onAudioDisabled,
          onAudioEnabled,
        );
      });

      // Attach handlers for any participants that connect in the future
      room.on('participantConnected', (participant) => {
        setupTrackEventHandlers(
          participant,
          onVideoDisabled,
          onVideoEnabled,
          onAudioDisabled,
          onAudioEnabled,
        );
      });

      // Detach the local media elements (takes place when either agent or user disconnects call)
      room.on('disconnected', (room) => {
        detachLocalTracks();
      });

      // Disconnect the room if the agent ends the call
      room.on('participantDisconnected', (participant) => {
        disconnectRoom();
        setShowVideo(false);
        setOpenEndCallDialog(true);
      });
    }
  }, [
    room,
    attachSubscribedTracks,
    detachLocalTracks,
    disconnectRoom,
    attachLocalAudioTrack,
    callType,
    setupTrackEventHandlers,
  ]);

  // handle any loading errors
  if (!clientDocument || !locationDocument) {
    return <div>Wird geladen...</div>;
  }

  // Function to save incoming call to db
  const saveIncomingCall = async (room) => {
    const incomingCall = {
      roomName: room.name,
      locationId: locationId,
      // TODO: consider using server timestamp!
      callStarted: new Date().toISOString(),
      completed: false,
    };

    const incomingCallDocRef = doc(db, 'incomingCalls', room.sid);

    try {
      await setDoc(incomingCallDocRef, incomingCall); // Set the document at the specified reference
      return incomingCallDocRef; // Return the document reference
    } catch (e) {
      console.error('Error adding document: ', e);
    }
  };

  const handleCall = async () => {

    if (availableAgents.length === 0) {
      setOpenNoAvailableAgentsDialog(true)
      return
    }

    setCallPending(true);

    setCallType('incoming');

    // Construct the room name
    const roomName = `${locationDocument.name}-${locationDocument.address}`;

    // Join the Twilio room
    const newRoom = await joinRoom(locationId, roomName);

    // Check for errors
    // TODO: display error message here!
    if (error) {
      console.error('Error during call:', error);
    }

    // Save incomingCall record to the db and get ref
    const incomingCallRef = await saveIncomingCall(newRoom);

    // Fetch the newly created record from the db
    const incomingCallDoc = await getDoc(incomingCallRef);

    // Check path to the db record exists, then emit socket event with relevant data
    if (incomingCallDoc.exists()) {
      const incomingCallData = incomingCallDoc.data();
      const documentId = incomingCallDoc.id;
      socketRef.current.emit('incoming-call', {
        roomName: incomingCallData.roomName,
        roomSid: documentId,
        locationId: locationDocument.id,
        timestamp: incomingCallData.callStarted,
      });
    } else {
      console.error('Incoming call document not found');
    }
  };

  const handleCancelCall = async () => {
    if (room) {
      // disconnect the room
      room.disconnect();

      socketRef.current.emit('call-cancelled', {
        roomName: room.name,
        roomSid: room.sid,
      });

      // update incoming call doc
      const updates = {
        actionType: 'cancelled',
        actionTimestamp: new Date().toISOString(),
        agent: '',
        completed: true,
      };
      await updateIncomingCallDocument(room.sid, updates);

      // hide call pending screen
      setCallPending(false);
    }
  };

  const handleCloseEndCallDialog = () => {
    setOpenEndCallDialog(false);
  };

  const handleCloseNoAvailableAgentsDialog = () => {
    setOpenNoAvailableAgentsDialog(false);
  };

  return (
    <>
      {callPending && (
        <CallPending handleCancelCall={handleCancelCall} room={room} />
      )}
      {openEndCallDialog && (
        <NotificationDialog
          open={openEndCallDialog}
          title="Anruf beendet"
          text="Agent hat den Anruf beendet"
          close={handleCloseEndCallDialog}
        />
      )}
      {openNoAvailableAgentsDialog && (
        <NotificationDialog
          open={openNoAvailableAgentsDialog}
          title="Keine verfügbaren Agenten"
          text="Leider sind alle Mitarbeiter in einem Gespräch, bitte warten Sie einen Augenblick."
          close={handleCloseNoAvailableAgentsDialog}
        />
      )}
      {showVideo ? (
        <VideoCall
          remoteVideoRef={remoteVideoRef}
          room={room}
          setShowVideo={setShowVideo}
          isAgent={false}
          callType={callType}
          isAgentCameraOn={isAgentCameraOn}
          isAgentMicOn={isAgentMicOn}
          setIsAgentCameraOn={setIsAgentCameraOn}
        />
      ) : (
        <>
          <div
            className="flex min-h-screen items-center justify-center px-6 py-24 sm:px-6 sm:py-32 lg:px-8"
            style={{ backgroundColor: locationDocument?.color?.hex }}
          >
            <div className="mx-auto max-w-2xl text-center">
              {/* Set max width and height for logo to maintain consistent sizing */}
              <img
                src={clientDocument.logoUrl}
                alt="client logo"
                className="mx-auto mb-6 max-h-60 max-w-xs rounded-2xl object-contain sm:max-w-sm md:max-w-md lg:max-w-lg"
              />

              <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                {locationDocument.name}
              </h2>
              <p className="mx-auto mt-6 max-w-xl text-lg leading-8 text-gray-600">
                {locationDocument.greeting
                  ? locationDocument.greeting
                  : `Button "Service rufen" drücken, Videotelefonie startet dann
                automatisch.`}
              </p>
              <div className="mt-10 flex items-center justify-center gap-x-6">
                <button
                  onClick={handleCall}
                  className="lg:text4xl flex items-center space-x-2 rounded-lg bg-indigo-600 px-16 py-10 text-2xl
          font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2
          focus-visible:outline-offset-2 focus-visible:outline-indigo-600 md:px-20 md:py-12 md:text-3xl lg:px-24 lg:py-14"
                >
                  <PhoneArrowUpRightIcon className="h-6 w-6 md:h-7 md:w-7 lg:h-8 lg:w-8" />
                  <span>
                    {locationDocument.buttonText
                      ? locationDocument.buttonText
                      : 'Service rufen'}
                  </span>
                </button>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}
