import { useState } from 'react';
import { useLogin } from '../../hooks/useLogin';
import { Alert } from '@mui/material';

// styles & images
import OracomLogo from '../../assets/oracom_logo_text.png';
import LoginBackground from '../../assets/background-auth.jpg';
import { EyeIcon, EyeSlashIcon } from '@heroicons/react/24/outline';

export default function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const { login, isPending, error } = useLogin();

  const handleSubmit = async (e) => {
    e.preventDefault();
    await login(email, password);

  };

  return (
    <>
      <div className="flex min-h-full flex-1">
        <div className="flex flex-1 flex-col justify-center px-4 py-12 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
          <div className="mx-auto w-full max-w-sm lg:w-96">
            {/* div with logo and invitation to log in/sign up*/}
            <div>
              <img className="h-10 w-auto" src={OracomLogo} alt="Oracom Logo" />
              <h2 className="mt-8 text-2xl font-bold leading-9 tracking-tight text-gray-900">
                Melde dich bei deinem Konto an
              </h2>
              {/* Error alert */}
              {error && <Alert severity="error" className="mt-4">{error}</Alert>}
            </div>

            {/* div with form inputs */}
            <div className={error ? "mt-4" : "mt-10"}>
              <div>
                <form onSubmit={handleSubmit} className="space-y-6">
                  {/* Email div */}
                  <div>
                    <label className="block text-sm font-medium leading-6 text-gray-900">
                      E-Mail: <span className="text-red-500">*</span>
                    </label>
                    <div className="mt-2">
                      <input
                        required
                        type="email"
                        onChange={(e) => setEmail(e.target.value)}
                        value={email}
                        className="block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      />
                    </div>
                  </div>

                  {/* Password div */}
                  <div>
                    <label className="block text-sm font-medium leading-6 text-gray-900">
                      Passwort: <span className="text-red-500">*</span>
                    </label>
                    <div className="relative mt-2">
                      <input
                        required
                        type={showPassword ? 'text' : 'password'}
                        onChange={(e) => setPassword(e.target.value)}
                        value={password}
                        className="block w-full rounded-md border-0 py-1.5 pr-10 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      />
                      <button
                        type="button"
                        onClick={() => setShowPassword(!showPassword)}
                        className="absolute inset-y-0 right-0 flex items-center px-2"
                      >
                        {showPassword ? (
                          <EyeSlashIcon className="h-5 w-5 text-gray-500" />
                        ) : (
                          <EyeIcon className="h-5 w-5 text-gray-500" />
                        )}
                      </button>
                    </div>
                  </div>

                  {/* Login button div */}
                  <div>
                    {!isPending && (
                      <button
                        type="submit"
                        className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                      >
                        Anmelden
                      </button>
                    )}
                    {isPending && (
                      <button
                        disabled
                        className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                      >
                        Anmeldung...
                      </button>
                    )}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>

        {/* div with side image */}
        <div className="relative hidden w-0 flex-1 lg:block">
          <img
            className="absolute inset-0 h-full w-full object-cover"
            src={LoginBackground}
            alt=""
          />
        </div>
      </div>
    </>
  );
}
