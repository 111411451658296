import { useAuthContext } from '../../hooks/useAuthContext'
import { useDocument } from '../../hooks/useDocument'
import { Fragment, useState } from 'react'
import { Dialog, Transition, Menu } from '@headlessui/react'
import { useLogout } from '../../hooks/useLogout'
import { NavLink } from 'react-router-dom';
// import { useEffect } from 'react'
import { useSelector } from 'react-redux'

// images & icons
import OracomLogoWhite from '../../assets/oracom_logo_white.png'
import {
  Bars3Icon,
  BellIcon,
  HomeIcon,
  UsersIcon,
  XMarkIcon,
  ListBulletIcon,
  PencilSquareIcon
} from '@heroicons/react/24/outline'
import { ChevronDownIcon } from '@heroicons/react/20/solid'

export default function Layout({ children}) {
  const { user, role } = useAuthContext()
  const { document } = useDocument('users', user.uid)
  const [sidebarOpen, setSidebarOpen] = useState(false)
  const { logout, isPending } = useLogout()
  // const [role, setRole] = useState(null)
  const incomingCalls = useSelector((state) => state.call.incomingCalls)


  // useEffect(() => {
  //   const fetchUserRole = async () => {
  //     if (user) {
  //       try {
  //         const idTokenResult = await await user.getIdTokenResult();
  //         const role = idTokenResult.claims.role;
  //         setRole(role)
  //       } catch (error) {
  //         console.error('Error fetching user role:', error);
  //       }
  //     }
  //   };

  //   fetchUserRole();
  // }, [user]);

  const navigation = [
    {
      name: 'Dashboard',
      href: '/',
      icon: HomeIcon,
      current: false,
      roles: ['admin', 'agent', 'team leader', 'customer manager']
    },
    {
      name: 'Kunden verwalten',
      href: '/clients',
      icon: PencilSquareIcon,
      current: false,
      roles: ['admin', 'agent', 'team leader', 'customer manager']
    },
    {
      name: 'Anrufverlauf',
      href: '/callhistory',
      icon: ListBulletIcon,
      current: false,
      roles: ['admin', 'team leader']
    },
    {
      name: 'Team',
      href: '/agents',
      icon: UsersIcon,
      current: false,
      roles: ['admin', 'team leader', 'agent', 'customer manager']
    }
  ]

  const userNavigation = [
    { name: 'Dein Profil', href: `/agents/${user.uid}` },
    { name: 'Einstellungen', href: `/agents/${user.uid}/settings` },
    { name: 'Abmelden', href: '#' },
  ]

  // Filter navigation items based on role
  const filteredNavigation = navigation.filter(item => item.roles.includes(role))

  function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }

  return (
    <>
      <div>
        <Transition.Root show={sidebarOpen} as={Fragment}>
          <Dialog as="div" className="relative z-50 lg:hidden" onClose={setSidebarOpen}>
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-900/80" />
            </Transition.Child>

            <div className="fixed inset-0 flex">
              <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="-translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="-translate-x-full"
              >
                <Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                      <button type="button" className="-m-2.5 p-2.5" onClick={() => setSidebarOpen(false)}>
                        <span className="sr-only">Seitenleiste schließen</span>
                        <XMarkIcon className="h-6 w-6 text-white" aria-hidden="true" />
                      </button>
                    </div>
                  </Transition.Child>
                  {/* Sidebar component, swap this element with another sidebar if you like */}
                  {/* I ADDED MY OWN PT-4 HERE */}
                  <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-indigo-600 pt-4 px-6 pb-4">
                    <div className="flex h-16 shrink-0 items-center">

                         <img
                         className="h-8 w-auto"
                         src={OracomLogoWhite}
                         alt="Oracom"
                       />

                    </div>
                    {/* I ADDED MY OWN PT-20 HERE */}
                    <nav className="flex flex-1 flex-col pt-20">
                      <ul className="flex flex-1 flex-col gap-y-7">
                        <li>
                          <ul className="-mx-2 space-y-1">
                          {filteredNavigation.map((item) => (
                              <li key={item.name}>
                                <NavLink
                                  to={item.href}
                                  className={({ isActive }) =>
                                    classNames(
                                      isActive ? 'bg-indigo-700 text-white' : 'text-indigo-200 hover:text-white hover:bg-indigo-700',
                                      'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold'
                                    )
                                  }
                                >
                                  <item.icon
                                    className="h-6 w-6 shrink-0 text-indigo-200 group-hover:text-white"
                                    aria-hidden="true"
                                  />
                                  {item.name}
                                </NavLink>
                              </li>
                            ))}
                          </ul>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>

        {/* Static sidebar for desktop */}
        <div className="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-72 lg:flex-col">
          {/* Sidebar component, swap this element with another sidebar if you like */}
          {/* I ADDED MY OWN PT-4 HERE */}
          <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-indigo-600 pt-4 px-6 pb-4">

            <div className="flex h-16 shrink-0 items-center">
              <img
                className="h-8 w-auto"
                src={OracomLogoWhite}
                alt="Oracom"
              />
            </div>
            {/* I ADDED MY OWN PT-20 HERE */}
            <nav className="flex flex-1 flex-col pt-20">
              <ul className="flex flex-1 flex-col gap-y-7">
                <li>
                  <ul className="-mx-2 space-y-1">
                  {filteredNavigation.map((item) => (
                      <li key={item.name}>
                        <NavLink
                          to={item.href}
                          className={({ isActive }) =>
                            classNames(
                              isActive ? 'bg-indigo-700 text-white' : 'text-indigo-200 hover:text-white hover:bg-indigo-700',
                              'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold'
                            )
                          }
                        >
                          <item.icon
                            className="h-6 w-6 shrink-0 text-indigo-200 group-hover:text-white"
                            aria-hidden="true"
                          />
                          {item.name}
                        </NavLink>
                      </li>
                    ))}
                  </ul>
                </li>
              </ul>
            </nav>
          </div>
        </div>

        <div className="lg:pl-72">
          <div className="sticky top-0 z-40 flex h-16 shrink-0 items-center gap-x-4 border-b border-gray-200 bg-white px-4 shadow-sm sm:gap-x-6 sm:px-6 lg:px-8">
            <button type="button" className="-m-2.5 p-2.5 text-gray-700 lg:hidden" onClick={() => setSidebarOpen(true)}>
              <span className="sr-only">Seitenleiste öffnen</span>
              <Bars3Icon className="h-6 w-6" aria-hidden="true" />
            </button>

            {/* Separator */}
            <div className="h-6 w-px bg-gray-900/10 lg:hidden" aria-hidden="true" />

            <div className="flex flex-1 gap-x-4 self-stretch lg:gap-x-6">

              {/* Self closing div to replace search bar */}
              <div className="relative flex flex-1" />

              <div className="flex items-center gap-x-4 lg:gap-x-6">
                <a
                href='/'
                type="button"
                className="-m-2.5 p-2.5 text-gray-400 hover:text-gray-500 relative"
                >
                  <span className="sr-only">Benachrichtigungen anzeigen</span>
                  <BellIcon className="h-6 w-6" aria-hidden="true" />

                  {/* Notification badge */}
                  {(incomingCalls.length >= 1 && document?.available) && (
                    <span className="absolute top-1.5 right-1.5 flex h-4 w-4 items-center justify-center rounded-full bg-red-600 text-white text-xs">
                      {incomingCalls.length}
                    </span>
                  )}
                </a>

                {/* Separator */}
                <div className="hidden lg:block lg:h-6 lg:w-px lg:bg-gray-900/10" aria-hidden="true" />

                {/* Profile dropdown className="relative"*/}
                <Menu as="div">
                  <Menu.Button className="-m-1.5 flex items-center p-1.5">
                    <span className="sr-only">Benutzermenü öffnen</span>
                    <img
                      className="h-8 w-8 rounded-full bg-gray-50 object-cover"
                      src={document?.photoURL}
                      alt="Profile thumbnail"
                    />
                    <span className="hidden lg:flex lg:items-center">
                      <span className="ml-4 text-sm font-semibold leading-6 text-gray-900" aria-hidden="true">
                        {document?.fullName}
                      </span>
                      <ChevronDownIcon className="ml-2 h-5 w-5 text-gray-400" aria-hidden="true" />
                    </span>
                  </Menu.Button>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="absolute right-0 z-10 mt-2.5 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
                      {userNavigation.map((item) => (
                        <Menu.Item key={item.name}>
                        {({ active }) => (
                          item.name === 'Abmelden' ? (
                            <button
                              onClick={logout}
                              disabled={isPending}
                              className={classNames(
                                active ? 'bg-gray-50' : '',
                                'block w-full text-left px-3 py-1 text-sm leading-6 text-gray-900'
                              )}
                            >
                              {isPending ? 'Abmeldung...' : 'Abmelden'}
                            </button>
                          ) : (
                            <a
                              href={item.href}
                              className={classNames(
                                active ? 'bg-gray-50' : '',
                                'block px-3 py-1 text-sm leading-6 text-gray-900'
                              )}
                            >
                              {item.name}
                            </a>
                          )
                        )}
                      </Menu.Item>
                      ))}
                    </Menu.Items>
                  </Transition>
                </Menu>
              </div>
            </div>
          </div>

          <main className="py-10">
            <div className="px-4 sm:px-6 lg:px-8">{children}</div>
          </main>
        </div>
      </div>
    </>
  )
}
