import { useParams, Link } from 'react-router-dom';
import { useDocument } from '../../hooks/useDocument';
import { useState, useEffect } from 'react';
import { storage } from '../../firebase/config';
import { useFirestore } from '../../hooks/useFirestore';
import { useNavigate } from 'react-router-dom';
import { useAuthContext } from '../../hooks/useAuthContext';
import axios from 'axios';
import { Alert } from '@mui/material';

// firebase imports
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';

// icons
import { UserCircleIcon } from '@heroicons/react/24/solid';

export default function EditAgent() {
  const { id } = useParams();
  const { document, error: documentError } = useDocument('users', id);
  const { updateDocument, response } = useFirestore('users');
  const [fullName, setFullName] = useState('');
  const [displayName, setDisplayName] = useState('');
  const [profilePicture, setProfilePicture] = useState(null);
  const [profilePictureError, setProfilePictureError] = useState(null);
  const navigate = useNavigate();
  const { user, role } = useAuthContext();
  const [agentRole, setAgentRole] = useState('');
  const [noUpdatedData, setNoUpdatedData] = useState(false);
  const [error, setError] = useState(null);

  const viewingOwnProfile = user.uid === id;

  useEffect(() => {
    if (document && document.role) {
      setAgentRole(document.role);
    }
  }, [document]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);

    // init empty object to store updates in
    const updatedData = {};

    if (fullName !== '') {
      updatedData.fullName = fullName;
    }

    if (displayName !== '') {
      updatedData.displayName = displayName;
    }

    if (profilePicture) {
      // Upload the profile picture to Firebase Storage
      const uploadPath = `thumbnails/${document.id}/${profilePicture.name}`;
      const storageRef = ref(storage, uploadPath);
      await uploadBytes(storageRef, profilePicture);

      // // Get the profile picture URL after the upload
      const profilePictureUrl = await getDownloadURL(storageRef);

      updatedData.photoURL = profilePictureUrl;
    }

    // Check if the role has changed and update it
    if (role === 'admin' && document.role !== agentRole) {
      updatedData.role = agentRole;
      try {
        const token = await user.getIdToken();
        const response = await axios.post(
          'https://api.oracom.de/update-user-role',
          {
            userId: document.id,
            newRole: agentRole,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        );

        // Check if the response status is 200 (OK)
        if (response.status !== 200) {
          setError('Fehler: Aktualisierung des Benutzers fehlgeschlagen.');
        }
      } catch (error) {
        setError('Fehler: Aktualisierung der Benutzerrolle fehlgeschlagen.');
      }
    }

    // Check if any data was provided for update
    if (Object.keys(updatedData).length === 0) {
      setNoUpdatedData(true);
      return;
    }

    await updateDocument(document.id, updatedData);
    if (!response.error) {
      navigate(`/agents/${document.id}`);
    } else {
      setError('Fehler: Aktualisierung der Benutzerrolle fehlgeschlagen');
    }
  };

  const handleFileChange = async (e) => {
    setProfilePicture(null);
    let selected = e.target.files[0];

    if (!selected.type.includes('image')) {
      setProfilePictureError('Die ausgewählte Datei muss ein Bild sein');
      return;
    }
    if (selected.size > 50 * 1024 * 1024) {
      setProfilePictureError('Die Bilddateigröße muss kleiner als 50MB sein');
      return;
    }

    setProfilePictureError(null);
    setProfilePicture(selected);
  };

  // Function to translate the roles to German
  const translateUserRole = (role) => {
    switch (role) {
      case 'admin':
        return 'Admin';
      case 'team leader':
        return 'Teamleiter';
      case 'customer manager':
        return 'Kundenmanagement';
      case 'agent':
        return 'Agent';
      default:
        return role;
    }
  };

  if (documentError) {
    return (
      <Alert severity="error">
        Fehler: Daten konnten nicht geladen werden.
      </Alert>
    );
  }

  if (!document) {
    return <div className="loading">Wird geladen...</div>;
  }

  return (
    <>
      <div className="space-y-10 divide-y divide-gray-900/10">
        <div className="grid grid-cols-1 gap-x-8 gap-y-8 md:grid-cols-3">
          <div className="px-4 sm:px-0">
            <h2 className="text-base font-semibold leading-7 text-gray-900">
              {viewingOwnProfile
                ? 'Mein Profil'
                : `Profil von ${document.displayName}`}{' '}
              bearbeiten
            </h2>
          </div>

          <form
            className="bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-2"
            onSubmit={handleSubmit}
          >
            <div className="px-4 py-6 sm:p-8">
              {/* show error messages */}
              {error && (
                <div className="sm:max-w-md">
                  <Alert severity="error" className="mb-4">
                    {error}
                  </Alert>
                </div>
              )}
              {profilePictureError && (
                <div className="sm:max-w-md">
                  <Alert severity="error" className=" mb-4">
                    {profilePictureError}
                  </Alert>
                </div>
              )}
              {noUpdatedData && (
                <div className="sm:max-w-md">
                  <Alert severity="error" className=" mb-4">
                    Keine aktualisierten Informationen angegeben.
                  </Alert>
                </div>
              )}
              <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                <div className="sm:col-span-4">
                  <label
                    htmlFor="website"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Vollständigen Namen ändern
                  </label>
                  <div className="mt-2">
                    <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                      <input
                        type="text"
                        className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                        onChange={(e) => setFullName(e.target.value)}
                        value={fullName}
                        placeholder={document.fullName}
                      />
                    </div>
                  </div>
                </div>

                <div className="sm:col-span-4">
                  <label
                    htmlFor="website"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Anzeigenamen ändern
                  </label>
                  <div className="mt-2">
                    <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                      <input
                        type="text"
                        className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                        onChange={(e) => setDisplayName(e.target.value)}
                        value={displayName}
                        placeholder={document.displayName}
                      />
                    </div>
                  </div>
                </div>

                {/* Profile Thumbnail Section */}
                <div className="col-span-full">
                  <label
                    htmlFor="photo"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Wähle ein neues Profilbild
                  </label>
                  <div className="mt-2 flex items-center gap-x-3">
                    <UserCircleIcon
                      className="h-12 w-12 text-gray-300"
                      aria-hidden="true"
                    />
                    <label
                      htmlFor="file-upload"
                      className="cursor-pointer rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                    >
                      Ändern
                    </label>
                    <input
                      id="file-upload"
                      type="file"
                      className="hidden"
                      onChange={handleFileChange}
                    />
                    {profilePicture && (
                      <span className="text-sm leading-6 text-gray-700">
                        {profilePicture.name}
                      </span>
                    )}
                  </div>
                </div>

                {/* Role section */}
                {role === 'admin' && !viewingOwnProfile && (
                  <div className="sm:col-span-4">
                    <div>
                      <label
                        htmlFor="location"
                        className="block text-sm font-medium leading-6 text-gray-900"
                      >
                        Rolle ändern
                      </label>
                      <select
                        id="role"
                        name="role"
                        className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        defaultValue={translateUserRole(document.role)}
                        value={agentRole}
                        onChange={(e) => setAgentRole(e.target.value)}
                      >
                        <option value="agent">Agent</option>
                        <option value="team leader">Teamleiter</option>
                        <option value="customer manager">
                          Kundenmanagement
                        </option>
                        <option value="admin">Admin</option>
                      </select>
                    </div>
                  </div>
                )}
              </div>
            </div>

            <div className="flex items-center justify-end gap-x-6 border-t border-gray-900/10 px-4 py-4 sm:px-8">
              <Link
                to={`/agents/${id}`}
                className="text-sm font-semibold leading-6 text-gray-900"
              >
                Abbrechen
              </Link>
              <button
                type="submit"
                className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                Speichern
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
