import { useFirestore } from '../../hooks/useFirestore'

// icons
import { PhoneXMarkIcon, VideoCameraIcon, VideoCameraSlashIcon, SpeakerXMarkIcon } from '@heroicons/react/24/solid'
import MicOnIcon from '../../assets/mic_on.svg'
import MicOffIcon from '../../assets/mic_off.svg'

export default function VideoButtons({
  room,
  setShowVideo,
  callType,
  isAgent,
  handleToggleCamera,
  handleToggleMicrophone,
  isCameraOn,
  isMicOn,
  isAgentMicOn
  }) {
  const { updateDocument } = useFirestore(callType === 'incoming' ? 'incomingCalls' : 'outgoingCalls')

  const handleEndCall = async () => {
    room.disconnect()
    setShowVideo(false)

    const updates = {
      callEnded: new Date().toISOString(),
      completed: true
    }

    await updateDocument(room.sid, updates)
  }

  return (
    <div className="absolute bottom-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2 flex gap-4 p-4">

      {isAgent && (
        <button
          onClick={handleToggleMicrophone}
          className="rounded-full bg-indigo-600 p-2 text-white shadow-sm hover:bg-indigo-500 focus:outline-none focus-visible:ring focus-visible:ring-indigo-300"
        >
            {isMicOn ?
              <img src={MicOffIcon} className="h-6 w-6 fill-current" aria-hidden="true" alt="Mic On" />
              :
              <img src={MicOnIcon} className="h-6 w-6 fill-current" aria-hidden="true" alt="Mic Off" />
            }
        </button>
      )}

      {!isAgent && !isAgentMicOn && (
        <button
        className="rounded-full p-1 text-white focus:outline-none"
        style={{ pointerEvents: 'none' }}
        >
          <SpeakerXMarkIcon className="h-6 w-6" aria-hidden="true" />
        </button>
      )}

        <button
        onClick={handleEndCall}
        className="rounded-full bg-red-600 p-2 text-white shadow-sm hover:bg-red-500 focus:outline-none focus-visible:ring focus-visible:ring-red-300"
        >
          <PhoneXMarkIcon className="h-6 w-6" aria-hidden="true" />
        </button>

      {isAgent && (
        <button
          onClick={handleToggleCamera}
          className="rounded-full bg-indigo-600 p-2 text-white shadow-sm hover:bg-indigo-500 focus:outline-none focus-visible:ring focus-visible:ring-indigo-300"
        >
          {isCameraOn ? <VideoCameraSlashIcon className="h-6 w-6" aria-hidden="true" /> : <VideoCameraIcon className="h-6 w-6" aria-hidden="true" />}
        </button>
      )}

    </div>
  )
}
