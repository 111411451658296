import { Link } from 'react-router-dom'

// icons
import { PlusSmallIcon } from '@heroicons/react/20/solid'

export default function PageHeader({ title, addButtonText, href, image, children, role }) {
  return (

<div className="md:flex md:items-center md:justify-between md:space-x-5">
  <div className="flex items-start space-x-5">

  {/* Add image to header if image prop is passed down */}
    {image && (
      <div className="flex-shrink-0">
        <div className="relative">
          <img
            className="h-16 w-16 rounded-full object-contain"
            src={image}
            alt='client logo'
          />
          <span className="absolute inset-0 rounded-full shadow-inner" aria-hidden="true" />
        </div>
      </div>
    )}

      <div className="min-w-0 flex-1 pt-1.5 flex items-center">
      <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">
        {title}
      </h2>
      </div>
  </div>

  {/* Buttons Section */}
  {role !== 'agent' && role !== 'team leader' && (
  <div className="mt-4 flex md:ml-4 md:mt-0">
    {children}
    <Link
      to={href}
      className="ml-3 inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
    >
      <PlusSmallIcon className="-ml-1.5 h-5 w-5" aria-hidden="true" />
      Neuer {addButtonText}
    </Link>
  </div>
  )}

</div>

  )
}
