import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { useAuthContext } from './hooks/useAuthContext';
// import { useEffect, useState } from 'react';

// pages and components
import Dashboard from './pages/dashboard/Dashboard';
import Login from './pages/login/Login';
import CreateClient from './pages/createclient/CreateClient';
import Clients from './pages/clients/Clients';
import Client from './pages/client/Client';
import EditClient from './pages/editclient/EditClient';
import CreateLocation from './pages/createlocation/CreateLocation';
import EditLocation from './pages/editlocation/EditLocation';
import Location from './pages/location/Location';
import Footer from './components/footer/Footer';
import CallHistory from './pages/callhistory/CallHistory';
import Agents from './pages/agents/Agents';
import Agent from './pages/agent/Agent';
import EditAgent from './pages/editagent/EditAgent';
import LayoutRoute from './components/layoutroute/LayoutRoute';
import CreateAgent from './pages/createagent/CreateAgent';
import FinishSignup from './pages/signup/FinishSignup';
import AccountSettings from './pages/accountsettings/AccountSettings';
// import LoadingSpinner from './components/loadingspinner/LoadingSpinner';

function App() {
  const { user, authIsReady, role } = useAuthContext();

  if (!authIsReady) {
    return <div>Wird geladen...</div>;
  }

  return (
    <>
      {authIsReady && (
        <BrowserRouter>
          <Routes>
            {user ? (
              <>
                <Route
                  path="/"
                  element={
                    <LayoutRoute>
                      <Dashboard />
                    </LayoutRoute>
                  }
                />
                <Route
                  path="/newclient"
                  element={
                    role === 'admin' || role === 'customer manager' ? (
                      <LayoutRoute>
                        <CreateClient />
                      </LayoutRoute>
                    ) : (
                      <Navigate to="/" />
                    )
                  }
                />
                <Route
                  path="/callhistory"
                  element={
                    role === 'admin' || role === 'team leader' ? (
                      <LayoutRoute>
                        <CallHistory />
                      </LayoutRoute>
                    ) : (
                      <Navigate to="/" />
                    )
                  }
                />
                <Route
                  path="/clients"
                  element={
                    <LayoutRoute>
                      <Clients />
                    </LayoutRoute>
                  }
                />
                <Route
                  path="/agents"
                  element={
                    <LayoutRoute>
                      <Agents />
                    </LayoutRoute>
                  }
                />
                <Route
                  path="/newagent"
                  element={
                    role === 'admin' || role === 'team leader' ? (
                      <LayoutRoute>
                        <CreateAgent />
                      </LayoutRoute>
                    ) : (
                      <Navigate to="/" />
                    )
                  }
                />
                <Route
                  path="/agents/:id"
                  element={
                    <LayoutRoute>
                      <Agent />
                    </LayoutRoute>
                  }
                />
                <Route
                  path="/agents/:id/settings"
                  element={
                    <LayoutRoute>
                      <AccountSettings />
                    </LayoutRoute>
                  }
                />
                <Route
                  path="/agents/:id/edit"
                  element={
                    <LayoutRoute>
                      <EditAgent />
                    </LayoutRoute>
                  }
                />
                <Route
                  path="/clients/:id"
                  element={
                    <LayoutRoute>
                      <Client />
                    </LayoutRoute>
                  }
                />
                <Route
                  path="/clients/:id/edit"
                  element={
                    role === 'admin' || role === 'customer manager' ? (
                      <LayoutRoute>
                        <EditClient />
                      </LayoutRoute>
                    ) : (
                      <Navigate to="/" />
                    )
                  }
                />
                <Route
                  path="/clients/:id/newlocation"
                  element={
                    role === 'admin' || role === 'customer manager' ? (
                      <LayoutRoute>
                        <CreateLocation />
                      </LayoutRoute>
                    ) : (
                      <Navigate to="/" />
                    )
                  }
                />
                <Route
                  path="/clients/:clientId/locations/:locationId/edit"
                  element={
                    role === 'admin' || role === 'customer manager' ? (
                      <LayoutRoute>
                        <EditLocation />
                      </LayoutRoute>
                    ) : (
                      <Navigate to="/" />
                    )
                  }
                />
                <Route
                  path="/clients/:clientId/locations/:locationId"
                  element={<Location />}
                />
              </>
            ) : (
              <>
                <Route path="/login" element={<Login />} />
                <Route path="/finish-signup" element={<FinishSignup />} />
                <Route path="*" element={<Navigate to="/login" />} />
              </>
            )}
          </Routes>
          <Footer />
        </BrowserRouter>
      )}
    </>
  );
}

export default App;

  // const [role, setRole] = useState(null);

  // useEffect(() => {
  //   const checkRoleAndNavigate = async () => {
  //     if (user) {
  //       try {
  //         const idTokenResult = await user.getIdTokenResult();
  //         const role = idTokenResult.claims.role;

  //         console.log('role:', role);
  //         setRole(role);
  //       } catch (error) {
  //         console.error('Error fetching ID token result:', error);
  //       }
  //     }
  //   };

  //   // Call the function when auth is ready
  //   if (authIsReady) {
  //     checkRoleAndNavigate();
  //   }
  // }, [user, authIsReady]);
