import { useParams, useNavigate, Link } from 'react-router-dom';
import { useDocument } from '../../hooks/useDocument';
import { useState, useEffect } from 'react';
import { useFirestore } from '../../hooks/useFirestore';
import { HexColorPicker, HexColorInput } from 'react-colorful';
import { Alert } from '@mui/material';

export default function EditLocation() {
  const { clientId, locationId } = useParams();
  const { document: locationDocument, error: locationDocumentError } =
    useDocument('locations', locationId);
  const [name, setName] = useState('');
  const [address, setAddress] = useState('');
  const [greeting, setGreeting] = useState('');
  const [buttonText, setButtonText] = useState('');
  const [color, setColor] = useState('#ffffff');
  const { updateDocument, response } = useFirestore('locations');
  const navigate = useNavigate();
  const [error, setError] = useState(null);

  useEffect(() => {
    if (locationDocument) {
      setColor(locationDocument.color || '#ffffff');
    }
  }, [locationDocument]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);

    // init empty object to store updates in
    const updatedData = {};

    if (name !== '') {
      updatedData.name = name;
    }

    if (address !== '') {
      updatedData.address = address;
    }

    if (greeting !== '') {
      updatedData.greeting = greeting;
    }

    if (buttonText !== '') {
      updatedData.buttonText = buttonText;
    }

    if (color !== locationDocument.color) {
      updatedData.color = color;
    }

    // Check if any data was provided for update
    if (Object.keys(updatedData).length === 0) {
      return;
    }
    try {
      await updateDocument(locationDocument.id, updatedData);
      if (response.error) {
        setError('Fehler: Ein Fehler ist aufgetreten.');
      } else {
        navigate(`/clients/${clientId}`);
      }
    } catch (err) {
      setError('Fehler: Beim Aktualisieren der Daten ist ein Fehler aufgetreten.');
    }
  };

  return (
    <>
      {locationDocumentError && (
        <Alert severity="error">
          Fehler: Daten konnten nicht geladen werden.
        </Alert>
      )}
      {locationDocument ? (
        <>
          <div className="space-y-10 divide-y divide-gray-900/10">
            <div className="grid grid-cols-1 gap-x-8 gap-y-8 md:grid-cols-3">
              <div className="px-4 sm:px-0">
                <h2 className="text-base font-semibold leading-7 text-gray-900">
                  {locationDocument.name} bearbeiten
                </h2>
              </div>

              <form
                className="bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-2"
                onSubmit={handleSubmit}
              >
                <div className="px-4 py-6 sm:p-8">
                  {/* show error message */}

                  {error && (
                    <div className="sm:max-w-md">
                      <Alert severity="error" className="mb-4">
                        {error}
                      </Alert>
                    </div>
                  )}

                  <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                    <div className="sm:col-span-4">
                      <label
                        htmlFor="website"
                        className="block text-sm font-medium leading-6 text-gray-900"
                      >
                        Namen ändern
                      </label>
                      <div className="mt-2">
                        <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                          <input
                            type="text"
                            placeholder={locationDocument.name}
                            className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                            onChange={(e) => setName(e.target.value)}
                            value={name}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="sm:col-span-4">
                      <label
                        htmlFor="website"
                        className="block text-sm font-medium leading-6 text-gray-900"
                      >
                        Adresse ändern
                      </label>
                      <div className="mt-2">
                        <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                          <input
                            type="text"
                            placeholder={locationDocument.address}
                            className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                            onChange={(e) => setAddress(e.target.value)}
                            value={address}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="sm:col-span-4">
                      <label
                        htmlFor="website"
                        className="block text-sm font-medium leading-6 text-gray-900"
                      >
                        Begrüßungsnachricht ändern
                      </label>
                      <div className="mt-2">
                        <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                          <input
                            type="text"
                            placeholder={
                              locationDocument.greeting
                                ? locationDocument.greeting
                                : 'Begrüßungsnachricht zur Anzeige für Kunden bearbeiten'
                            }
                            className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                            onChange={(e) => setGreeting(e.target.value)}
                            value={greeting}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="sm:col-span-4">
                      <label
                        htmlFor="website"
                        className="block text-sm font-medium leading-6 text-gray-900"
                      >
                        Button-Text ändern
                      </label>
                      <div className="mt-2">
                        <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                          <input
                            type="text"
                            placeholder={
                              locationDocument.buttonText
                                ? locationDocument.buttonText
                                : 'Bearbeite den Text, der auf dem Anruf-Button erscheinen soll'
                            }
                            className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                            onChange={(e) => setButtonText(e.target.value)}
                            value={buttonText}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="col-span-full">
                      <label
                        htmlFor="cover-photo"
                        className="block text-sm font-medium leading-6 text-gray-900"
                      >
                        Die Hintergrundfarbe der Standortseite ändern
                      </label>
                      <div className="mt-6 flex flex-col items-start">
                        <HexColorPicker color={color} onChange={setColor} />
                        <div className="mt-4">
                          <HexColorInput
                            color={color}
                            onChange={setColor}
                            prefixed
                            className="font-inherit rounded-md border border-gray-300 bg-gray-100 text-center uppercase outline-none focus:border-blue-500"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="flex items-center justify-end gap-x-6 border-t border-gray-900/10 px-4 py-4 sm:px-8">
                  <Link
                    to={`/clients/${clientId}`}
                    className="text-sm font-semibold leading-6 text-gray-900"
                  >
                    Abbrechen
                  </Link>
                  <button
                    type="submit"
                    className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                  >
                    Speichern
                  </button>
                </div>
              </form>
            </div>
          </div>
        </>
      ) : (
        <p>Wird geladen...</p>
      )}
    </>
  );
}
    // await updateDocument(locationDocument.id, updatedData);
    // if (!response.error) {
    //   navigate(`/clients/${clientId}`);
    // }
    // if (response.error) {
    //   setError('Fehler: Ein Fehler ist aufgetreten.');
    // }
