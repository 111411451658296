import { useEffect, useState, useRef } from 'react'
import { db } from '../firebase/config'

// firebase imports
import { collection, onSnapshot, query, orderBy, where } from 'firebase/firestore'

export const useCollection = (collectionName, _q, _order) => {
  const [documents, setDocuments] = useState(null)
  const [error, setError] = useState(null)

  // convert query (_q) and orderBy (_order) conditions to refs to avoid infinite loop
  const q = useRef(_q).current
  const order = useRef(_order).current


  useEffect(() => {
    // init firestore collection reference
    let ref = collection(db, collectionName)

    // build the query using provided conditions
    if (q) {
      ref = query(ref, where(...q))
    }
    if (order) {
      ref = orderBy(ref, ...order)
    }

    // subscribe to changes
    const unsub = onSnapshot(ref, (snapshot) => {
      let results = []
      snapshot.docs.forEach((doc) => {
        results.push({ id: doc.id, ...doc.data() })
      })
      setDocuments(results)
      setError(null)
    }, (error) =>{
      console.error(error)
      setError('Fehler: Daten konnten nicht abgerufen werden.')
    })

    // unsubscribe on unmount
    return () => unsub()


  }, [collectionName, q, order])

  return { documents, error }
}
