import { useState, useEffect, useRef } from 'react'
import { Player } from '@lordicon/react'

// icons
import { PhoneIcon } from '@heroicons/react/20/solid'
import { PhoneXMarkIcon } from '@heroicons/react/20/solid'

const ICON = require('../../assets/ringing_phone_solid.json');

export default function CallListItem({ call, index, incomingCalls, handleAcceptCall, handleDeclineCall }) {
  const [elapsedTime, setElapsedTime] = useState(0);
  const playerRef = useRef(null);

  useEffect(() => {
    playerRef.current?.playFromBeginning();
  }, [playerRef])

  useEffect(() => {
    const initialTime = new Date(call.timestamp).getTime();
    const timerId = setInterval(() => {
      const currentTime = new Date().getTime();
      setElapsedTime(Math.floor((currentTime - initialTime) / 1000));
    }, 1000);

    return () => clearInterval(timerId);
  }, [call]);

  const formatTime = (seconds) => {
    const mins = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${mins}:${secs < 10 ? '0':''}${secs}`;
  }

  return (
  <li key={call.roomSid}>
    <div className="relative pb-8 mt-4">
      {incomingCalls.length > 1 && index !== incomingCalls.length - 1 ? (
      <span className="absolute left-4 top-4 -ml-px h-full w-0.5 bg-gray-200" aria-hidden="true" />
      ) : null}
      <div className="relative flex space-x-3">
        <div>
        <span
          className='bg-blue-500 h-8 w-8 rounded-full flex items-center justify-center ring-8 ring-white'
        >
          <Player
          ref={playerRef}
          size={22}
          icon={ ICON }
          colorize='#FFFFFF'
          onComplete={() => playerRef.current?.playFromBeginning()}
        />
        </span>
        </div>
        <div className="flex min-w-0 flex-1 justify-between space-x-4 pt-1.5">
        <div>
          <p className="text-sm text-gray-500">
            Incoming call from{' '}
            <span className="font-medium text-gray-900 mr-4">
              {call.roomName}
            </span>
            <button
            className="inline-flex items-center rounded-full bg-green-400 px-2 py-1 text-xs font-medium text-green-800 mr-4"
            onClick={() => handleAcceptCall(call)}
            >
            <PhoneIcon
            className="h-4 w-4 ml-1 mr-1"
            />
            </button>
            <button
            className="inline-flex items-center rounded-full bg-red-400 px-2 py-1 text-xs font-medium text-red-800"
            onClick={() => handleDeclineCall(call)}
            >
            <PhoneXMarkIcon
            className="h-4 w-4 ml-1 mr-1"
            />
            </button>
          </p>
          </div>
          <div className="whitespace-nowrap text-right text-sm text-gray-500">
            {formatTime(elapsedTime)}{' '}
          </div>
        </div>
      </div>
    </div>
  </li>
  )
}
