import { initializeApp } from 'firebase/app'
import { getFirestore } from 'firebase/firestore'
import { getAuth } from 'firebase/auth'
import { getStorage } from 'firebase/storage'

const firebaseConfig = {
  apiKey: "AIzaSyAZZ8K0rxUCOBg2fzcWg__NE90ILhhfR10",
  authDomain: "digital-concierge-6ff2c.firebaseapp.com",
  projectId: "digital-concierge-6ff2c",
  storageBucket: "digital-concierge-6ff2c.appspot.com",
  messagingSenderId: "529217663708",
  appId: "1:529217663708:web:52a6ccf7f88564f54fba19"
};

// init firebase
initializeApp(firebaseConfig)

// init firestore
const db = getFirestore()

// init firebase auth
const auth = getAuth()

// init firebase storage
const storage = getStorage()

export { db, auth, storage }
