import axios from 'axios'
import { connect } from 'twilio-video'
import { useState } from 'react'

export const useTwilioVideo = () => {
  const [room, setRoom] = useState(null)
  const [error, setError] = useState(null)

 // Function to fetch access token
 const fetchAccessToken = async (identity) => {
  try {
    const response = await axios.get(`https://twilio-service-digital-concierge-4436-dev.twil.io/token-service?identity=${identity}`)
    return response.data.accessToken
  } catch (error) {
    throw new Error('Error fetching access token: ' + error.message)
  }
}

// Function to join a room
const joinRoom = async (identity, roomName) => {
  try {
    const accessToken = await fetchAccessToken(identity);

    const newRoom = await connect(accessToken, {
      name: roomName,
      video: { width: 640, height: 480 },
      audio: true
    });
    setRoom(newRoom);
    return newRoom
  } catch (error) {
    console.error('Error connecting to room:', error);
    if (error.code) {
      console.error('Twilio API error code:', error.code);
    }

    if (error.message) {
      console.error('Twilio API error message:', error.message);
    }

    if (error.details) {
      console.error('Twilio API error details:', error.details);
    }

    setError(error);
    throw error
  }
}

return { joinRoom, room, error, setError }
}
