import { useDocument } from '../../hooks/useDocument';
import { useParams } from 'react-router-dom';
import { Link, useNavigate } from 'react-router-dom';
import { useAuthContext } from '../../hooks/useAuthContext';
import { useState } from 'react';
import { getAuth } from 'firebase/auth';
import axios from 'axios';
import { Alert } from '@mui/material';

import DeleteAlert from '../../components/deletealert/DeleteAlert';

export default function Agent() {
  const { id } = useParams();
  const { document, error: documentError } = useDocument('users', id);
  const { user, role } = useAuthContext();
  const [openDeleteUserAlert, setOpenDeleteUserAlert] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const auth = getAuth();

  // Function to translate the roles to German
  const translateUserRole = (role) => {
    switch (role) {
      case 'admin':
        return 'Admin';
      case 'team leader':
        return 'Teamleiter';
      case 'customer manager':
        return 'Kundenmanagement';
      case 'agent':
        return 'Agent';
      default:
        return role;
    }
  };

  //Function to delete a user
  const handleDeleteUser = async (userId) => {
    setError(null);
    try {
      // fetch token:
      const token = await auth.currentUser.getIdToken();

      // make request to delete user end point:
      const response = await axios.post(
        'https://api.oracom.de/delete-user',
        {
          userId,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            Accept: 'application/json',
          },
        },
      );

      // Check if the response status is 200 (OK)
      if (response.status !== 200) {
        setError('Fehler: Benutzer konnte nicht gelöscht werden.');
      }

      // Successfully deleted user from Firestore and Authentication
      navigate('/agents', { state: { deletionSuccess: true } });
    } catch (error) {
      // Handle deletion errors
      setError('Fehler: Benutzer konnte nicht gelöscht werden.');
      setOpenDeleteUserAlert(false);
    }
  };

  if (documentError) {
    return (
      <Alert severity="error">
        Fehler: Daten konnten nicht geladen werden.
      </Alert>
    );
  }

  if (!document) {
    return <div className="loading">Wird geladen...</div>;
  }
  return (
    <>
      {error && (
        <Alert severity="error" className="mb-4">
          {error}
        </Alert>
      )}
      <div className="overflow-hidden bg-white shadow sm:rounded-lg">
        <div className="flex items-center justify-between px-4 py-6 sm:px-6">
          <div>
            <h3 className="text-base font-semibold leading-7 text-gray-900">
              {document.fullName}
            </h3>
            <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-500">
              Persönliche Informationen
            </p>
          </div>
          <img
            className="inline-block h-14 w-14 rounded-full object-cover"
            src={document.photoURL}
            alt=""
          />
        </div>
        <div className="border-t border-gray-100">
          <dl className="divide-y divide-gray-100">
            <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-900">Anzeigename</dt>
              <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                {document.displayName}
              </dd>
            </div>
            <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-900">Rolle</dt>
              <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                {translateUserRole(document.role)}
              </dd>
            </div>
            <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-900">
                E-Mail-Adresse
              </dt>
              <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                {document.email}
              </dd>
            </div>
            <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-900">Telefon</dt>
              <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                0123456789
              </dd>
            </div>
            {/* Optional additional fields, such as about */}
            {/* <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-900">About</dt>
            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
              Fugiat ipsum ipsum deserunt culpa aute sint do nostrud anim incididunt cillum culpa consequat. Excepteur
              qui ipsum aliquip consequat sint. Sit id mollit nulla mollit nostrud in ea officia proident. Irure nostrud
              pariatur mollit ad adipisicing reprehenderit deserunt qui eu.
            </dd>
          </div> */}
          </dl>
        </div>
      </div>
      <div className="flex items-center justify-end gap-x-3 border-t border-gray-900/10 px-4 py-4 sm:px-8">
        <Link
          to="/agents"
          className="text-sm font-semibold leading-6 text-gray-900"
        >
          Zurück
        </Link>
        {role === 'admin' && (
          <button
            type="button"
            className="inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
            onClick={() => setOpenDeleteUserAlert(true)}
          >
            Löschen
          </button>
        )}
        {openDeleteUserAlert && (
          <DeleteAlert
            open={openDeleteUserAlert}
            close={() => setOpenDeleteUserAlert(false)}
            handleDelete={handleDeleteUser}
            title={`${document.fullName} entfernen`}
            text={`Bist du sicher, dass du das Konto von ${document.fullName} dauerhaft löschen möchtest?`}
            userId={document.id}
          ></DeleteAlert>
        )}
        {(role === 'admin' ||
          role === 'team leader' ||
          document.id === user.uid) && (
          <Link
            to={`/agents/${document.id}/edit`}
            className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            Bearbeiten
          </Link>
        )}
      </div>
    </>
  );
}
