import { useState, useEffect } from 'react';
import { Switch } from '@headlessui/react';
import { useFirestore } from '../../hooks/useFirestore';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function AvailabilityToggle({ userDocument }) {
  const [available, setAvailable] = useState(
    userDocument ? userDocument.available : false,
  );
  const { updateDocument: updateUserDocument, response: userDocumentResponse } =
    useFirestore('users');

  useEffect(() => {
    // Initialize the 'available' state variable based on userDocument - this is so that
    // the toggle stays on the correct setting upon page refresh
    if (userDocument) {
      setAvailable(userDocument.available);
    }
  }, [userDocument]);

  const handleToggle = async () => {
    setAvailable((prevAvailable) => !prevAvailable);

    await updateUserDocument(userDocument.id, {
      available: !available,
    });
    if (userDocumentResponse.error) {
      console.error('Error');
    }
  };

  return (
    <Switch.Group as="div" className="mt-1 flex items-center justify-between">
      <span className="flex flex-grow flex-col">
        <Switch.Label
          as="span"
          className="text-sm font-medium leading-6 text-gray-900"
          passive
        >
          Verfügbar
        </Switch.Label>
      </span>
      <Switch
        checked={available}
        onChange={handleToggle}
        className={classNames(
          available ? 'bg-indigo-600' : 'bg-gray-200',
          'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2',
        )}
      >
        <span
          aria-hidden="true"
          className={classNames(
            available ? 'translate-x-5' : 'translate-x-0',
            'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out',
          )}
        />
      </Switch>
    </Switch.Group>
  );
}
