import { createContext, useReducer, useEffect } from 'react';
import { auth } from '../firebase/config';
import { onAuthStateChanged } from 'firebase/auth';

export const AuthContext = createContext();

export const authReducer = (state, action) => {
  switch (action.type) {
    case 'LOGIN':
      return { ...state, user: action.payload.user, role: action.payload.role };
    case 'LOGOUT':
      return { ...state, user: null, role: null };
    case 'AUTH_IS_READY':
      return { user: action.payload.user, role: action.payload.role, authIsReady: true };
    default:
      return state;
  }
};

export const AuthContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(authReducer, {
    user: null,
    role: null,
    authIsReady: false,
  });

  useEffect(() => {
    const unsubAuth = onAuthStateChanged(auth, async user => {
      if (user) {
        try {
          const idTokenResult = await user.getIdTokenResult();
          const role = idTokenResult.claims.role;
          dispatch({ type: 'AUTH_IS_READY', payload: { user, role } });
        } catch (error) {
          console.error('Error fetching ID token result:', error);
        }
      } else {
        dispatch({ type: 'AUTH_IS_READY', payload: { user: null, role: null } });
      }
    });

    return () => unsubAuth();
  }, []);

  return (
    <AuthContext.Provider value={{ ...state, dispatch }}>
      {children}
    </AuthContext.Provider>
  );
};


// import { createContext, useReducer, useEffect, useState } from 'react';
// import { auth, db } from '../firebase/config';
// import { onAuthStateChanged } from 'firebase/auth';
// import { doc, onSnapshot } from 'firebase/firestore';

// export const AuthContext = createContext();

// export const authReducer = (state, action) => {
//   switch (action.type) {
//     case 'LOGIN':
//       return { ...state, user: action.payload };
//     case 'LOGOUT':
//       return { ...state, user: null };
//     case 'AUTH_IS_READY':
//       return { user: action.payload, authIsReady: true };
//     case 'SET_ROLE':
//       return { ...state, role: action.payload };
//     default:
//       return state;
//   }
// };

// export const AuthContextProvider = ({ children }) => {
//   const [state, dispatch] = useReducer(authReducer, {
//     user: null,
//     authIsReady: false,
//     role: null,
//   });

//   const [userProfile, setUserProfile] = useState(null);

//   useEffect(() => {
//     const unsubAuth = onAuthStateChanged(auth, async (user) => {
//       dispatch({ type: 'AUTH_IS_READY', payload: user });
//       if (user) {
//         try {
//           const idTokenResult = await user.getIdTokenResult();
//           const tokenRole = idTokenResult.claims.role;
//           dispatch({ type: 'SET_ROLE', payload: tokenRole });

//           // Fetch the user profile from Firestore
//           const ref = doc(db, 'users', user.uid);
//           const unsubProfile = onSnapshot(
//             ref,
//             (snapshot) => {
//               if (snapshot.exists()) {
//                 const profileData = snapshot.data();
//                 setUserProfile({ id: snapshot.id, ...profileData });

//                 // Update role if it differs from tokenRole
//                 if (profileData.role !== tokenRole) {
//                   dispatch({ type: 'SET_ROLE', payload: profileData.role });
//                 }
//               } else {
//                 setUserProfile(null);
//               }
//             },
//             (error) => {
//               console.error('Error fetching user profile:', error);
//             }
//           );

//           return () => {
//             unsubProfile();
//             unsubAuth();
//           };
//         } catch (error) {
//           console.error('Error fetching ID token result:', error);
//         }
//       } else {
//         setUserProfile(null);
//       }
//     });

//     return () => {
//       unsubAuth();
//     };
//   }, []);

//   return (
//     <AuthContext.Provider value={{ ...state, userProfile, dispatch }}>
//       {children}
//     </AuthContext.Provider>
//   );
// };


// import { createContext, useReducer, useEffect, useState } from 'react';
// import { auth, db } from '../firebase/config';
// import { onAuthStateChanged } from 'firebase/auth';
// import { doc, onSnapshot } from 'firebase/firestore';

// export const AuthContext = createContext();

// export const authReducer = (state, action) => {
//   switch (action.type) {
//     case 'LOGIN':
//       return { ...state, user: action.payload };
//     case 'LOGOUT':
//       return { ...state, user: null };
//     case 'AUTH_IS_READY':
//       return { user: action.payload, authIsReady: true };
//     default:
//       return state;
//   }
// };

// export const AuthContextProvider = ({ children }) => {
//   const [state, dispatch] = useReducer(authReducer, {
//     user: null,
//     authIsReady: false,
//   });

//   const [userProfile, setUserProfile] = useState(null);

//   useEffect(() => {
//     const unsubAuth = onAuthStateChanged(auth, user => {
//       dispatch({ type: 'AUTH_IS_READY', payload: user });
//       if (user) {
//         // Fetch the user profile from Firestore
//         const ref = doc(db, 'users', user.uid);
//         const unsubProfile = onSnapshot(ref, (snapshot) => {
//           if (snapshot.exists()) {
//             setUserProfile({ id: snapshot.id, ...snapshot.data() });
//           } else {
//             // Handle case where user document does not exist
//             setUserProfile(null);
//           }
//         }, (error) => {
//           console.error('Error fetching user profile:', error);
//           // Optionally set an error state here
//         });

//         return () => {
//           unsubProfile();
//           unsubAuth();
//         };
//       } else {
//         setUserProfile(null);
//       }
//     });

//     return () => {
//       unsubAuth();
//     };
//   }, []);

//   return (
//     <AuthContext.Provider value={{ ...state, userProfile, dispatch }}>
//       {children}
//     </AuthContext.Provider>
//   );
// };

  // useEffect(() => {
  //   const unsub = onAuthStateChanged(auth, (user) => {
  //     dispatch({ type: 'AUTH_IS_READY', payload: user });
  //     unsub();
  //   });
  // }, []);

// useEffect(() => {
//   const unsub = onAuthStateChanged(auth, async (user) => {
//     console.log("Auth state changed. User:", user);
//     if (user) {
//       try {
//       const idTokenResult = await user.getIdTokenResult();
//       console.log("ID Token Result:", idTokenResult);
//       console.log('idTokenResult.claims.role', idTokenResult.claims.role)

//       // Check if 'role' is defined in claims, use a default role if not
//       const role = idTokenResult.claims.role || 'defaultRole';

//       dispatch({
//         type: 'AUTH_IS_READY',
//         payload: { user, role }
//       });
//     } catch (error) {
//       console.error("Error fetching ID token result:", error);
//       }
//     } else {
//       dispatch({ type: 'AUTH_IS_READY', payload: { user: null, role: 'defaultRole' } });
//     }
//     unsub();
//   });
// }, []);

// testing
