import { useCallback } from 'react'

export const useTwilioTracks = (room, remoteVideoRef, localVideoRef) => {

  // function to add local video tracks
  const attachLocalVideoTrack = useCallback(() => {
    if (!localVideoRef.current) return;

    room.localParticipant.videoTracks.forEach(publication => {
      const videoTrack = publication.track;
      if (videoTrack && localVideoRef.current) {
        const videoElement = videoTrack.attach();
        videoElement.classList.add('w-full', 'h-full', 'rounded-lg');
        localVideoRef.current.appendChild(videoElement);
      }
    });
  }, [room, localVideoRef])

  // function to add local audio tracks
  const attachLocalAudioTrack = useCallback(() => {
    room.localParticipant.audioTracks.forEach(publication => {
      const audioTrack = publication.track;
      if (audioTrack) {
        // Create an audio element but do not append it to the DOM
        const audioElement = document.createElement('audio');
        audioElement.autoplay = true; // Ensures the audio track is active
        audioTrack.attach(audioElement);
      }
    });
  }, [room])

  // function to add newly subscribed tracks (to call upon room 'participantConnected' and 'trackSubscribed')
  const attachSubscribedTracks = useCallback((track) => {
    if (!remoteVideoRef.current) return; // Ensure the ref is available

    let element;
    if (track.kind === 'video') {
      element = track.attach();
      element.classList.add('w-full', 'h-full', 'block', 'mx-auto', 'rounded-lg');
    } else if (track.kind === 'audio') {
      element = document.createElement('audio');
      track.attach(element);
    }

    if (element) {
      remoteVideoRef.current.appendChild(element);
    }
  }, [remoteVideoRef]);

    // function to detach local tracks (to call upon room 'disconnected')
    const detachLocalTracks = useCallback(() => {
      if (room && room.localParticipant) {
        room.localParticipant.tracks.forEach(publication => {
          const attachedElements = publication.track.detach();
          attachedElements.forEach(element => element.remove());
        });
      }
    }, [room]);

    // function to log disconnected participants and disconnect the room
    const disconnectRoom = useCallback(() => {
      room.disconnect()
    }, [room])

     // Toggle camera visibility
    const toggleCamera = useCallback(() => {
      room.localParticipant.videoTracks.forEach(publication => {
        if (publication.track.isEnabled) {
          publication.track.disable()
        } else {
          publication.track.enable()
        }
      });
    }, [room])

  // Toggle microphone
  const toggleMicrophone = useCallback(() => {
    room.localParticipant.audioTracks.forEach(publication => {
      if (publication.track.isEnabled) {
        publication.track.disable()
      } else {
        publication.track.enable()
      }
    });
  }, [room]);

  // Function to listen to and respond to camera/audio mute/unmute
  const setupTrackEventHandlers = useCallback((participant, onVideoDisabled, onVideoEnabled, onAudioDisabled, onAudioEnabled) => {
    participant.tracks.forEach(publication => {
      const attachTrackHandlers = (track) => {
        if (track.kind === 'video') {
          track.on('disabled', () => {
            onVideoDisabled();
          });
          track.on('enabled', () => {
            onVideoEnabled();
          });
        }
        if (track.kind === 'audio') {
          track.on('disabled', () => {
            onAudioDisabled();
          });
          track.on('enabled', () => {
            onAudioEnabled();
          });
        }
      };

      if (publication.isSubscribed) {
        attachTrackHandlers(publication.track);
      }

      publication.on('subscribed', attachTrackHandlers);
    });
  }, []);

  // Function to reattach the remote video track
  const reattachRemoteVideoTrack = useCallback(() => {
    // Assuming that remote participants are already in the room
        room.participants.forEach(participant => {
          participant.videoTracks.forEach(publication => {
          if (publication.isSubscribed && publication.track.kind === 'video') {
          const videoTrack = publication.track;
          if (videoTrack && remoteVideoRef.current) {
          const videoElement = videoTrack.attach();
          videoElement.classList.add('w-full', 'h-full', 'block', 'mx-auto', 'rounded-lg');
          remoteVideoRef.current.appendChild(videoElement);
          }
        }
      })
    })
  }, [room, remoteVideoRef])


return {
  detachLocalTracks,
  attachSubscribedTracks,
  disconnectRoom,
  attachLocalVideoTrack,
  attachLocalAudioTrack,
  toggleCamera,
  toggleMicrophone,
  setupTrackEventHandlers,
  reattachRemoteVideoTrack
 }
}
