import { Navigate, useParams } from 'react-router-dom';
import { useAuthContext } from '../../hooks/useAuthContext';
import { useState, useEffect } from 'react';
import {
  getAuth,
  reauthenticateWithCredential,
  EmailAuthProvider,
  updatePassword,
  verifyBeforeUpdateEmail,
} from 'firebase/auth';
import { Alert } from '@mui/material';

// icons
import { EyeIcon, EyeSlashIcon } from '@heroicons/react/24/outline';

// components
import SuccessAlert from '../../components/successalert/SuccessAlert';

export default function AccountSettings() {
  const { user } = useAuthContext();
  const { id } = useParams();

  const auth = getAuth();
  const authUser = auth.currentUser;

  const [openSuccessAlert, setOpenSuccessAlert] = useState(false);
  const [successAlertMessage, setSuccessAlertMessage] = useState('');

  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordsMatch, setPasswordsMatch] = useState(true);
  const [passwordError, setPasswordError] = useState(null);

  const [newEmail, setNewEmail] = useState('');
  const [confirmEmail, setConfirmEmail] = useState('');
  const [emailsMatch, setEmailsMatch] = useState(true);
  const [passwordForEmailError, setPasswordForEmailError] = useState(null);
  const [newEmailError, setNewEmailError] = useState(null);

  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const [showEmailCurrentPassword, setShowEmailCurrentPassword] =
    useState(false);

  // use effect hook so that the success alert is closed by clicking anywhere
  useEffect(() => {
    const closeAlert = () => setOpenSuccessAlert(false);
    if (openSuccessAlert) {
      window.addEventListener('click', closeAlert);
    }
    return () => {
      window.removeEventListener('click', closeAlert);
    };
  }, [openSuccessAlert]);

  // use effect hook to make the success alert close by itself after a few seconds
  useEffect(() => {
    if (openSuccessAlert) {
      const timer = setTimeout(() => {
        setOpenSuccessAlert(false);
      }, 2000); // 2000 milliseconds = 2 seconds
      return () => clearTimeout(timer);
    }
  }, [openSuccessAlert]);

  // Redirect if the current user's ID does not match the ID in the URL
  if (!user || user.uid !== id) {
    return <Navigate to="/" />;
  }

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
    setPasswordsMatch(true); // Reset match status when confirmation changes
  };

  const handleSubmitPassword = async (e) => {
    setPasswordError(null);
    e.preventDefault();

    if (newPassword !== confirmPassword) {
      setPasswordsMatch(false);
      return;
    }

    const credential = EmailAuthProvider.credential(
      authUser.email,
      currentPassword,
    );

    try {
      await reauthenticateWithCredential(authUser, credential);
      await updatePassword(authUser, newPassword);
      setSuccessAlertMessage('Passwort erfolgreich aktualisiert');
      setOpenSuccessAlert(true);
      setCurrentPassword('');
      setNewPassword('');
      setConfirmPassword('');
    } catch (error) {
      console.error('An error occurred:', error);
      if (error.code === 'auth/invalid-credential') {
        setPasswordError('Ein Fehler ist aufgetreten: ungültiges Passwort');
      } else if (error.code === 'auth/weak-password') {
        setPasswordError(
          'Fehler: schwaches Passwort. Passwörter sollten mindestens 6 Zeichen enthalten',
        );
      } else {
        setPasswordError('Ein Fehler ist aufgetreten');
      }
    }
  };

  const handleSubmitEmail = async (e) => {
    e.preventDefault();

    setEmailsMatch(true);
    setPasswordForEmailError(null);
    setNewEmailError(null);

    if (newEmail !== confirmEmail) {
      setEmailsMatch(false);
      return;
    }

    if (newEmail === authUser.email) {
      setNewEmailError(
        'Diese E-Mail-Adresse ist bereits mit deinem Konto verknüpft',
      );
      return;
    }

    const credential = EmailAuthProvider.credential(
      authUser.email,
      currentPassword,
    );

    try {
      await reauthenticateWithCredential(authUser, credential);

      const actionCodeSettings = {
        url: 'https://digital.oracom.de/',
      };

      await verifyBeforeUpdateEmail(authUser, newEmail, actionCodeSettings);

      setSuccessAlertMessage('Bestätigungs-E-Mail gesendet');
      setOpenSuccessAlert(true);

      setCurrentPassword('');
      setNewEmail('');
      setConfirmEmail('');
    } catch (error) {
      console.error(error);
      if (error.code === 'auth/invalid-credential') {
        setPasswordForEmailError('Fehler: falsches Passwort');
      } else {
        setNewEmailError('Ein Fehler ist aufgetreten');
      }
    }
  };

  return (
    <>
      {openSuccessAlert && (
        <SuccessAlert
          open={openSuccessAlert}
          close={() => setOpenSuccessAlert(false)}
          text={successAlertMessage}
        ></SuccessAlert>
      )}
      <div className="space-y-10 divide-y divide-gray-900/10">
        <div className="grid grid-cols-1 gap-x-8 gap-y-8 md:grid-cols-3">
          <div className="px-4 sm:px-0">
            <h2 className="text-base font-semibold leading-7 text-gray-900">
              Kontoeinstellungen bearbeiten
            </h2>
          </div>

          <form
            className="bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-2"
            onSubmit={handleSubmitPassword}
          >
            <div className="px-4 py-6 sm:p-8">
              <div>
                <h2 className="text-base font-semibold leading-7 text-gray-900">
                  Passwort ändern
                </h2>
                <p
                  className={
                    !passwordsMatch || passwordError
                      ? 'mb-3 mt-1 text-sm leading-6 text-gray-900'
                      : 'mb-6 mt-1 text-sm leading-6 text-gray-900'
                  }
                >
                  Passwort deines Kontos aktualisieren.
                </p>
                {/* Show error messages */}
                {!passwordsMatch && (
                  <div className="sm:max-w-md">
                    <Alert severity="error" className="mb-3">
                      Fehler: Passwörter stimmen nicht überein.
                    </Alert>
                  </div>
                )}
                {passwordError && (
                  <div className="sm:max-w-md">
                    <Alert severity="error" className="mb-3">
                      {passwordError}
                    </Alert>
                  </div>
                )}
              </div>

              <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                <div className="sm:col-span-4">
                  <label
                    htmlFor="website"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Aktuelles Passwort
                  </label>
                  <div className="relative mt-2">
                    <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                      <input
                        required
                        placeholder="Gib dein aktuelles Passwort ein"
                        type={showCurrentPassword ? 'text' : 'password'}
                        className="block w-full rounded-md border-0 py-1.5 pr-10 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        onChange={(e) => setCurrentPassword(e.target.value)}
                        value={currentPassword}
                      />
                      <button
                        type="button"
                        onClick={() =>
                          setShowCurrentPassword(!showCurrentPassword)
                        }
                        className="absolute inset-y-0 right-0 flex items-center px-2"
                      >
                        {showCurrentPassword ? (
                          <EyeSlashIcon className="h-5 w-5 text-gray-500" />
                        ) : (
                          <EyeIcon className="h-5 w-5 text-gray-500" />
                        )}
                      </button>
                    </div>
                  </div>
                </div>

                <div className="sm:col-span-4">
                  <label
                    htmlFor="website"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Neues Passwort
                  </label>
                  <div className="relative mt-2">
                    <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                      <input
                        required
                        type={showNewPassword ? 'text' : 'password'}
                        placeholder="Passwörter sollten mindestens 6 Zeichen enthalten"
                        className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                        onChange={(e) => setNewPassword(e.target.value)}
                        value={newPassword}
                        minLength="6"
                      />
                      <button
                        type="button"
                        onClick={() => setShowNewPassword(!showNewPassword)}
                        className="absolute inset-y-0 right-0 flex items-center px-2"
                      >
                        {showNewPassword ? (
                          <EyeSlashIcon className="h-5 w-5 text-gray-500" />
                        ) : (
                          <EyeIcon className="h-5 w-5 text-gray-500" />
                        )}
                      </button>
                    </div>
                  </div>
                </div>

                <div className="sm:col-span-4">
                  <label
                    htmlFor="website"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Passwort bestätigen
                  </label>
                  <div className="relative mt-2">
                    <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                      <input
                        required
                        type={showConfirmPassword ? 'text' : 'password'}
                        placeholder="Passwörter sollten mindestens 6 Zeichen enthalten"
                        className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                        onChange={handleConfirmPasswordChange}
                        value={confirmPassword}
                        minLength="6"
                      />
                      <button
                        type="button"
                        onClick={() =>
                          setShowConfirmPassword(!showConfirmPassword)
                        }
                        className="absolute inset-y-0 right-0 flex items-center px-2"
                      >
                        {showConfirmPassword ? (
                          <EyeSlashIcon className="h-5 w-5 text-gray-500" />
                        ) : (
                          <EyeIcon className="h-5 w-5 text-gray-500" />
                        )}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="flex items-center justify-end gap-x-6 border-t border-gray-900/10 px-4 py-4 sm:px-8">
              <button
                type="submit"
                className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                Speichern
              </button>
            </div>
          </form>

          {/* Section divider */}
          <div className="px-4 sm:px-0 md:col-span-1" />

          <form
            className="bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-2"
            onSubmit={handleSubmitEmail}
          >
            <div className="px-4 py-6 sm:p-8">
              <div>
                <h2 className="text-base font-semibold leading-7 text-gray-900">
                  E-Mail ändern
                </h2>
                <p className={
                    !emailsMatch || passwordForEmailError || newEmailError
                      ? 'mb-3 mt-1 text-sm leading-6 text-gray-900'
                      : 'mb-6 mt-1 text-sm leading-6 text-gray-900'
                  }>
                  Sende eine Bestätigungs-E-Mail, um die mit deinem Konto
                  verknüpfte E-Mail-Adresse zu aktualisieren.
                </p>
                {/* Show error messages */}
                {!emailsMatch && (
                  <div className="sm:max-w-md">
                    <Alert severity="error" className="mb-3">
                      Fehler: E-Mail-Adressen stimmen nicht überein
                    </Alert>
                  </div>
                )}
                {passwordForEmailError && (
                  <div className="sm:max-w-md">
                    <Alert severity="error" className="mb-3">
                      {passwordForEmailError}
                    </Alert>
                  </div>
                )}
                {newEmailError && (
                  <div className="sm:max-w-md">
                    <Alert severity="error" className="mb-3">
                      {newEmailError}
                    </Alert>
                  </div>
                )}
              </div>

              <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                <div className="sm:col-span-4">
                  <label
                    htmlFor="website"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Neue E-Mail
                  </label>
                  <div className="mt-2">
                    <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                      <input
                        required
                        placeholder="Neue E-Mail-Adresse eingeben"
                        type="text"
                        className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                        onChange={(e) => setNewEmail(e.target.value)}
                        value={newEmail}
                      />
                    </div>
                  </div>
                </div>

                <div className="sm:col-span-4">
                  <label
                    htmlFor="website"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    E-Mail bestätigen
                  </label>
                  <div className="mt-2">
                    <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                      <input
                        required
                        placeholder="Neue E-Mail-Adresse bestätigen"
                        type="text"
                        className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                        onChange={(e) => setConfirmEmail(e.target.value)}
                        value={confirmEmail}
                      />
                    </div>
                  </div>
                </div>

                <div className="sm:col-span-4">
                  <label
                    htmlFor="website"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Passwort eingeben
                  </label>
                  <div className="relative mt-2">
                    <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                      <input
                        required
                        placeholder="Gib dein Passwort ein"
                        type={showEmailCurrentPassword ? 'text' : 'password'}
                        className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                        onChange={(e) => setCurrentPassword(e.target.value)}
                        value={currentPassword}
                      />
                      <button
                        type="button"
                        onClick={() =>
                          setShowEmailCurrentPassword(!showEmailCurrentPassword)
                        }
                        className="absolute inset-y-0 right-0 flex items-center px-2"
                      >
                        {showEmailCurrentPassword ? (
                          <EyeSlashIcon className="h-5 w-5 text-gray-500" />
                        ) : (
                          <EyeIcon className="h-5 w-5 text-gray-500" />
                        )}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="flex items-center justify-end gap-x-6 border-t border-gray-900/10 px-4 py-4 sm:px-8">
              <button
                type="submit"
                className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                Speichern
              </button>
            </div>
          </form>

          {/* Section divider */}
          <div className="px-4 sm:px-0 md:col-span-1" />
        </div>
      </div>
    </>
  );
}
