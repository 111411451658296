import { useSelector } from 'react-redux'

// styles & icons
import { PhoneXMarkIcon } from '@heroicons/react/24/outline'

export default function MissedCallList({ handleReturnCall }) {
  // Access the missed calls array from Redux store
  const reduxMissedCalls = useSelector(state => state.missedCall.missedCalls)

  // Function to display the time in hours and mins
  const formatTime = (timestamp) => {
    const date = new Date(timestamp);
    let hours = date.getHours();
    let minutes = date.getMinutes();

    // Add leading zero to minutes if less than 10
    minutes = minutes < 10 ? '0' + minutes : minutes;

    return `${hours}:${minutes}`;
  }

  return (
    <>
      {reduxMissedCalls &&
      [...reduxMissedCalls]
      .sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp))
      .map((call) => (
        <div key={call.roomSid} className="group relative flex gap-x-6 rounded-lg p-4 hover:bg-gray-50">
          <div className="mt-1 flex h-11 w-11 flex-none items-center justify-center rounded-full bg-red-50 group-hover:bg-green-50">
            <PhoneXMarkIcon className="h-6 w-6 text-red-600 group-hover:text-green-600" aria-hidden="true" />
          </div>
          <div>
            <button onClick={() => handleReturnCall(call)}
            className="font-semibold text-gray-900">
              {call.roomName}
              <span className="absolute inset-0" />
            </button>
            <p className="mt-1 text-gray-600">Missed at: {formatTime(call.timestamp)}</p>
          </div>
        </div>
      ))}
    </>
  )
}

// old function where we sorted calls from the db from no more than 30mins ago:
  // Filter so that we fetch only calls that were missed in the last 30mins
  // const missedCalls = documents ? documents.filter((call) => {
  //   if (call.actionType !== 'missed') return false

  //   const callTime = new Date(call.actionTimestamp)
  //   const now = new Date()
  //   const thirtyMinutes = 30 * 60 * 1000 // 30mins in miliseconds
  //   return now - callTime < thirtyMinutes
  // })
  // .sort((a, b) => new Date(b.actionTimestamp) - new Date(a.actionTimestamp)) // Sorting in descending order
  // : []
