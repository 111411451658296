import { useState, useRef } from 'react';
import { useFirestore } from '../../hooks/useFirestore';
import { useNavigate, Link } from 'react-router-dom';
import { storage } from '../../firebase/config';
import { Alert } from '@mui/material';

// firebase imports
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';

// icons
import { PhotoIcon } from '@heroicons/react/24/solid';

export default function CreateClient() {
  const [name, setName] = useState('');
  const [logo, setLogo] = useState(null);
  const [logoError, setLogoError] = useState(null);
  const [error, setError] = useState(null);
  const { addDocument, updateDocument, response } = useFirestore('clients');
  const navigate = useNavigate();
  const fileInputRef = useRef();

  const handleSubmit = async (e) => {
    e.preventDefault();

    setError(null);

    const client = {
      name,
      locations: [],
    };

    const docRef = await addDocument(client);

    if (response.error) {
      setError('Fehler: Beim Erstellen des Kunden ist ein Fehler aufgetreten.');
      return;
    }

    if (docRef) {
      try {
        // Upload the logo to Firebase Storage
        const uploadPath = `client-logos/${docRef.id}/${logo.name}`;
        const storageRef = ref(storage, uploadPath);
        await uploadBytes(storageRef, logo);

        // // Get the logo URL after the upload
        const logoUrl = await getDownloadURL(storageRef);

        // Update the client document with the logo URL
        await updateDocument(docRef.id, { logoUrl: logoUrl });

        if (!response.error) {
          navigate('/clients');
        } else {
          setError(
            'Fehler: Beim Aktualisieren des Kundenlogos ist ein Fehler aufgetreten.',
          );
        }
      } catch (err) {
        console.error('Error uploading logo:', err.message);
        setError(
          'Fehler: Beim Hochladen des Logos ist ein Fehler aufgetreten.',
        );
      }
    }
  };

  const handleFileChange = (e) => {
    e.preventDefault();

    let selected;
    if (e.type === 'drop') {
      selected = e.dataTransfer.files[0];
    } else {
      selected = e.target.files[0];
    }

    if (!selected) {
      setLogoError('Fehler: Bitte wähle eine Datei aus');
      return;
    }
    if (!selected.type.includes('image')) {
      setLogoError('Fehler: Die ausgewählte Datei muss ein Bild sein');
      return;
    }
    if (selected.size > 50 * 1024 * 1024) {
      setLogoError('Fehler: Die Bilddateigröße muss kleiner als 50MB sein');
      return;
    }

    setLogoError(null);
    setLogo(selected);
  };

  return (
    <>
      <div className="space-y-10 divide-y divide-gray-900/10">
        <div className="grid grid-cols-1 gap-x-8 gap-y-8 md:grid-cols-3">
          <div className="px-4 sm:px-0">
            <h2 className="text-base font-semibold leading-7 text-gray-900">
              Neuen Kunden erstellen
            </h2>
          </div>

          <form
            className="bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-2"
            onSubmit={handleSubmit}
          >
            <div className="px-4 py-6 sm:p-8">
              {/* Display error messages */}
              {logoError && (
                <div className="sm:max-w-md">
                  <Alert severity="error" className="mb-4">
                    {logoError}
                  </Alert>
                </div>
              )}
              {error && (
                <div className="sm:max-w-md">
                  <Alert severity="error" className=" mb-4">
                    {error}
                  </Alert>
                </div>
              )}
              <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                <div className="sm:col-span-4">
                  <label
                    htmlFor="website"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Kundenname <span className="text-red-500">*</span>
                  </label>
                  <div className="mt-2">
                    <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                      <input
                        required
                        type="text"
                        placeholder="Gib den Namen des Kunden ein"
                        className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                        onChange={(e) => setName(e.target.value)}
                        value={name}
                      />
                    </div>
                  </div>
                </div>

                <div className="col-span-full">
                  <label
                    htmlFor="cover-photo"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Kundenlogo <span className="text-red-500">*</span>
                  </label>
                  <div
                    className="mt-2 flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-10"
                    onDrop={handleFileChange}
                    onDragOver={(e) => e.preventDefault()}
                  >
                    {!logo && (
                      <div className="text-center">
                        <PhotoIcon
                          className="mx-auto h-12 w-12 text-gray-300"
                          aria-hidden="true"
                        />
                        <div className="mt-4 flex text-sm leading-6 text-gray-600">
                          <label
                            htmlFor="file-upload"
                            className="relative cursor-pointer rounded-md bg-white font-semibold text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-600 focus-within:ring-offset-2 hover:text-indigo-500"
                          >
                            <span>Datei hochladen</span>
                            <input
                              required
                              id="file-upload"
                              name="file-upload"
                              type="file"
                              className="sr-only"
                              ref={fileInputRef}
                              onChange={handleFileChange}
                            />
                          </label>
                          <p className="pl-1">oder per Drag & Drop ablegen</p>
                        </div>
                        <p className="text-xs leading-5 text-gray-600">
                          Bilddatei bis zu 50MB
                        </p>
                      </div>
                    )}
                    {logo && (
                      <div className="mt-2 text-center text-sm text-gray-600">
                        {logo.name}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="flex items-center justify-end gap-x-6 border-t border-gray-900/10 px-4 py-4 sm:px-8">
              <Link
                to="/clients"
                className="text-sm font-semibold leading-6 text-gray-900"
              >
                Abbrechen
              </Link>
              <button
                type="submit"
                className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                Speichern
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
