import { useEffect, useState } from 'react'
import { useAuthContext } from '../hooks/useAuthContext'

// firebase imports
import { auth, db } from '../firebase/config'
import { signOut } from 'firebase/auth'
import { updateDoc, doc } from 'firebase/firestore'

export const useLogout = () => {
  const [isCancelled, setIsCancelled] = useState(false)
  const [error, setError] = useState(null)
  const [isPending, setIsPending] = useState(false)
  const { dispatch } = useAuthContext()

  const logout = async () => {
    setError(null)
    setIsPending(true)

    try {
      // update agent availability to false
      const { uid } = auth.currentUser
      const userDocRef = doc(db, 'users', uid)
      await updateDoc(userDocRef, {available: false})

      // sign user out
      await signOut(auth)

      // dispatch logout action
      dispatch({ type: 'LOGOUT' })

      // update state
      if (!isCancelled) {
        setIsPending(false)
        setError(null)
      }

    } catch(err) {
      if (!isCancelled) {
        setError(err.message)
        setIsPending(false)
      }
    }

    setIsPending(false)
  }

  useEffect(() => {
    return () => setIsCancelled(true)
  }, [])

  return { logout, error, isPending }
}
