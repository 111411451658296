import { useState } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { useFirestore } from '../../hooks/useFirestore';
import { useDocument } from '../../hooks/useDocument';
import { HexColorPicker, HexColorInput } from 'react-colorful';
import { Alert } from '@mui/material';

// firebase imports
import { arrayUnion } from 'firebase/firestore';

export default function Location() {
  const [name, setName] = useState('');
  const [address, setAddress] = useState('');
  const [color, setColor] = useState('#ffffff');
  const [greeting, setGreeting] = useState('');
  const [buttonText, setButtonText] = useState('');
  const [error, setError] = useState(null);
  const { id } = useParams();
  const { addDocument: addLocation, response: locationResponse } =
    useFirestore('locations');
  const { updateDocument: updateClient, response: clientResponse } =
    useFirestore('clients');
  const { document: clientDocument } = useDocument('clients', id);
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);

    // // create a new location
    const location = {
      name,
      address,
      color,
      clientId: id,
      greeting,
      buttonText,
    };

    // // add location document to firestore
    const locationRef = await addLocation(location);

    // // update client doc by addition location ref id to locations array
    await updateClient(clientDocument.id, {
      locations: arrayUnion(locationRef.id),
    });

    if (clientResponse.error) {
      console.error('error updating client:', clientResponse);
      setError('Fehler: Ein Fehler ist aufgetreten.');
    }

    if (!locationResponse.error) {
      navigate(`/clients/${id}`);
    } else {
      setError('Fehler: Ein Fehler ist aufgetreten.');
    }
  };

  return (
    <>
      <div className="space-y-10 divide-y divide-gray-900/10">
        <div className="grid grid-cols-1 gap-x-8 gap-y-8 md:grid-cols-3">
          <div className="px-4 sm:px-0">
            <h2 className="text-base font-semibold leading-7 text-gray-900">
              Neuen Standort erstellen
            </h2>
          </div>

          <form
            className="bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-2"
            onSubmit={handleSubmit}
          >
            <div className="px-4 py-6 sm:p-8">
              {error && (
                <div className="sm:max-w-md">
                  <Alert severity="error" className=" mb-4">
                    {error}
                  </Alert>
                </div>
              )}
              <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                <div className="sm:col-span-4">
                  <label
                    htmlFor="website"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Standortname <span className="text-red-500">*</span>
                  </label>
                  <div className="mt-2">
                    <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                      <input
                        required
                        type="text"
                        placeholder="Gib den Namen deines Standorts ein"
                        className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                        onChange={(e) => setName(e.target.value)}
                        value={name}
                      />
                    </div>
                  </div>
                </div>

                <div className="sm:col-span-4">
                  <label
                    htmlFor="website"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Standortadresse <span className="text-red-500">*</span>
                  </label>
                  <div className="mt-2">
                    <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                      <input
                        required
                        type="text"
                        placeholder="Gib die Adresse deines Standorts ein"
                        className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                        onChange={(e) => setAddress(e.target.value)}
                        value={address}
                      />
                    </div>
                  </div>
                </div>

                <div className="sm:col-span-4">
                  <label
                    htmlFor="website"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Begrüßungsnachricht
                  </label>
                  <div className="mt-2">
                    <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                      <input
                        type="text"
                        placeholder="Gib eine Begrüßung ein, die den Benutzern angezeigt wird"
                        className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                        onChange={(e) => setGreeting(e.target.value)}
                        value={greeting}
                      />
                    </div>
                  </div>
                </div>

                <div className="sm:col-span-4">
                  <label
                    htmlFor="website"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Button-Text
                  </label>
                  <div className="mt-2">
                    <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                      <input
                        type="text"
                        placeholder="Gib den Text ein, der auf dem Anruf-Button erscheinen soll"
                        className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                        onChange={(e) => setButtonText(e.target.value)}
                        value={buttonText}
                      />
                    </div>
                  </div>
                </div>

                <div className="sm:col-span-4">
                  <label
                    htmlFor="cover-photo"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Wähle die Hintergrundfarbe der Standortseite
                  </label>
                  <div className="mt-6 flex flex-col items-start">
                    <HexColorPicker color={color} onChange={setColor} />
                    <div className="mt-4">
                      <HexColorInput
                        color={color}
                        onChange={setColor}
                        prefixed
                        className="font-inherit rounded-md border border-gray-300 bg-gray-100 text-center uppercase outline-none focus:border-blue-500"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex items-center justify-end gap-x-6 border-t border-gray-900/10 px-4 py-4 sm:px-8">
              <Link
                to="/clients"
                className="text-sm font-semibold leading-6 text-gray-900"
              >
                Abbrechen
              </Link>
              <button
                type="submit"
                className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                Speichern
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
